
.view-commerce-cart-form-override, #cart-page {

	.table-striped>tbody>tr:nth-of-type(odd) {
	    background-color: transparent;
	}
	.table>thead>tr>th {
		border:0px;
	}
	.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
		border:0px;

	}
	.table.views-table>tbody>tr>td, .table>tbody>tr>th, .table.views-table>tfoot>tr>td, .table>tfoot>tr>th {
    			border-bottom: 1px solid #ddd;
	}
	.table.views-table>tbody>tr:first-child>td, .table.views-table>tbody>tr:first-child>th {
    			border-top: 1px solid #ddd;
	}
	.table.views-table>tbody>tr>td:first-child, .table.views-table>tbody>tr>th:first-child {
    			border-left: 1px solid #ddd;
	}
	.table.views-table>tbody>tr>td:last-child, .table.views-table>tbody>tr>th:last-child {
    			border-right: 1px solid #ddd;
	}
	table {
		border-collapse: inherit;
		tbody>tr>td {
			padding:15px;
		}
    	img {
    		float: left;
    		margin-right: 50px;
    	}
	}
	.table-hover>tbody>tr:hover {
	    background-color: transparent;
	}
	thead tr th:first-child {
		font-size:18px;
		text-align:left;
	}
	thead tr th {
		font-size:14px;
		text-align:center;
	}
	tbody tr td:first-child {
		text-align:left;
	}
	tbody tr td:last-child {
		text-align:right;
	}
	tbody tr td {
		font-size:16px;
		text-align:center;
	}

	.cart-title {
		font-size: 16px;
		font-weight: 500;
	}
	.cart-sku {
	    font-weight: 100;
	}

	.commerce-order-handler-area-order-total .commerce-price-formatted-components {
		width:100%;
	}

	table.commerce-price-formatted-components {
		padding:30px 20px;
		font-size:15px;
    	border: 1px solid #ddd;
		tbody>tr>td {
			padding:3px;
		}
    	tr {
    		text-align: right;
    	}

	  	tbody>tr:nth-of-type(odd) {
		    background-color: transparent;
		}
		thead>tr>th {
			border:0px;
		}
		tbody>tr>td, tbody>tr>th, tfoot>tr>td, tfoot>tr>th, thead>tr>td, thead>tr>th {
			border:0px;
		}
    	.component-type-commerce-price-formatted-amount, #your-order {
    		font-size: 20px;
    		font-weight: bold;
    		text-transform: uppercase;
    		td {
    			padding-top:10px;
    		}
    	}
	}
}

.billing-address,
#payment-method-options {
	h3 {
		margin-bottom: 10px;
	}
}
.final-checkout, .pagalba {
	.question {
		font-size: 17px;
		font-weight: 500;
	}
	.number {
		font-size: 20px;
		font-weight: 400;
	}
	p {
		font-size: 13px;
		font-weight: 400;
	}
}

#your-order, #order_summary {
	padding-bottom: 20px;
	.table-responsive {
		padding:20px 20px;
		font-size:15px;
    	border: 1px solid #ddd;
		table {
			margin-bottom: 0px;
			tbody>tr>td {
				padding:3px;
			}
	    	tr {
	    		// text-align: right;
	    	}

		  	tbody>tr:nth-of-type(odd) {
			    background-color: transparent;
			}
			thead>tr>th {
				border:0px;
			}
			tbody>tr>td, tbody>tr>th, tfoot>tr>td, tfoot>tr>th, thead>tr>td, thead>tr>th {
				border:0px;
			}
	    	.component-type-commerce-price-formatted-amount, #your-order {
	    		font-size: 18px;
	    		font-weight: bold;
	    		text-transform: uppercase;
	    		td {
	    			padding-top:10px;
	    		}
	    		td:last-child {
	    			min-width: 90px;
	    		}
	    	}
	}
	}
}

.commerce_payment {
    border: 1px solid #ddd;
    margin-top: 15px;
    padding: 15px 20px;
}

.field-name-commerce-customer-address, #customer-profile-billing-ajax-wrapper {
	.form-type-textfield {
		width: 100%;
	}
	.addressfield-container-inline {
		.form-item-customer-profile-shipping-commerce-customer-address-und-0-postal-code,
		.form-item-customer-profile-billing-commerce-customer-address-und-0-postal-code {
	    	width: 100%;
	    	float: left;
		}
		.form-item-customer-profile-shipping-commerce-customer-address-und-0-locality,
		.form-item-customer-profile-billing-commerce-customer-address-und-0-locality {
		    width: 100%;
		    float: right;
		    margin-right: 0px;
		}
	}
}

.commerce-add-to-cart {
	.form-wrapper.form-group {
		margin-bottom: 0px;
	}
}

.block-commerce-extra-plus {
	float:left;
	border-left:1px solid #bbb;
	padding-left:1em;
	margin-bottom:2em;
	width: 40%;
	margin-right: 10%;
	padding: 0px;
	margin-top: 40px;
    margin-bottom: 50px;
    h3 {
    	margin-bottom: 25px;
    	margin-left: 33%;
    }
}
.not-logged-in.page-checkout #commerce-checkout-form-login  {
	margin-left: 10%;
    float: left;
    width: 30%;
	h3 {
    	margin-bottom: 25px;
    	text-align: center;
    }
}
.commerce_payment > .form-radios {
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}
#submit-ignore-isblocked-debtsumma {
	display: inline-block;
	margin-right: 5px;
}

#cart .commerce-price-savings-formatter-list .price-amount {
	text-align: center;
}
