$red: #d44554;
$light_gray: #e5e5e5;


$d-green: #4e8985;
$l-green: #94b7b5;
$gray : #888888;
$pink : #ff5963;

@mixin clearfix{
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}
.pager-clearfix {
    width: 100%;
    float:left;
}
.pager-height {
    height: 100px;
}

body {
    font-family: 'Roboto', regular;
    color: #737a7f;
    font-size: 15px;
    line-height: 1.5em;
    font-weight: 400;
}

p {
    margin-bottom: 10px;
    font-weight: 400;
}
p.last {
    margin-bottom: 35px;
}
.field ul {
    margin-left: 20px;
}

b, strong {
    font-weight: 700;
}

h1 {
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    font-size: 28px;
    margin-bottom: 20px;
    font-weight: 400;
}

h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 22px;
    margin-bottom:15px;
}

h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
}

h4 {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
}
h5 {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    a {
        color: #7c7c7c;
    }
}

section {
    padding-top: 40px;
    padding-bottom: 50px;
}
select {
    border-radius: 0px!important;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-position: right 5px top 50%;
    background-image: url(../img/select-arrow.png)!important;
    padding: .5em;
    padding-right: 1.5em;
}

.field-type-text-long, .field-type-text-with-summary {
    ul {
        list-style: inherit;
        li{
            margin-bottom: 5px;
            font-weight: 400;
        }
    }
}

.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}
.text-italic {
    font-style: italic;
}
.hidden {
    display:none;
}
.no-margin {
    padding: 0;
    margin: 0;
}
.not-active {
    pointer-events: none;
    cursor: pointer;
}

img {
    max-width: 100%;
    height: auto;
}


.line-bottom {
    border-bottom: 3px solid #d2d3d4;
}
.line-top {
    border-top: 3px solid #d2d3d4;
}
.border-block {
    border: 1px solid #d2d3d4;
}


.bg-red {
    background-color: #d44554;
}
.bg-dark-gray {
    background-color: #d5d5d5;
}
.bg-light-gray {
    background-color: #e5e5e5;
}
.bg-white {
    background-color: #fff;
}
.bg-gray {
    background-color: #f5f5f5;
}

.icon-padding {
    padding: 0px 5px;
}

.flag-thumbnail {
    width: 20px;
    height: 14px;
    position: relative;
    float: left;
    margin: 3px;
}
.more-link {
    text-transform: uppercase;
    font-weight: 500;
    color: #737a7f;
    cursor: pointer;
    margin-left: 15px;
    margin-right: 15px;
}
.more-link:hover {
    color: #585858;
    text-decoration: none;
}
.navbar-toggle {
    background-color: #F5F5F5;
    .icon-bar {
        background-color: #000;
    }
}

.alert-block {
    margin-top: 10px;
}
.svydis_stock_validation_checkout_pane .alert-success {
    color: #fff;
    background-color: #d44554;
    border-color: #d44554
}
.sv-close{
    position: relative;
    z-index: 10;
}
span.manager_name {
    display: inherit;
    margin-bottom: 5px;
}
.no-padding {
    padding: 0!important;
}
