
.front-category {
    background: url(../img/front-background.png);
    height: 400px;
    background-repeat: no-repeat;
    background-position: center center;
    -moz-background-size: cover;
    background-size: cover;
    border-bottom: 15px solid #d44554;
    .container {
	    position: relative;
	    height: 100%;
    	.ft-category-wrap {
	    	position: absolute;
	    	bottom: -55px;
		    width: 100%;
		    .ft-category {
		    	text-transform: uppercase;
		    	h2, h2 a {
		    		color:#fff;
    				font-weight: 400;
		    		font-size: 26px;
		    		margin-bottom:40px;
    				text-shadow: 2px 1px 0 #000;
		    	}
		    	h3 {
		    		font-family: 'Roboto Condensed', sans-serif;
		    		color: #909090;
		    		font-size: 14px;
		    		font-weight: 700;
		    		line-height: initial;
		    		padding: 0px 3px;
		    	}
		    	a {
		    		text-decoration: none;
		    	}
		    	a:hover h3 {
		    		// color:#585858;
		    		color:#899295;
                	/*transition: all .3s;*/
		    	}
		    	img, a h3 {
		    		/*transition: all 0.3s;*/
		    	}
		    	img {
		    	    display: inline-block;
		    	    -webkit-filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
    				filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
		    	}
		    	a:hover img {
		    	    -webkit-filter: drop-shadow(0px 0px 7px rgba(0,0,0,0.8));
    				filter: drop-shadow(0px 0px 7px rgba(0,0,0,0.8));
		    	}
		    }
    	}
    }
}


.i18n-ru .front-category .container .ft-category-wrap .ft-category h2 {
	font-size: 23px;
}

.news-grid-teaser {
	h3 {
		font-size:18px;
		font-weight:500;
	}
	background-color:#fff;
	-webkit-box-shadow: 3px 9px 3px -6px rgba(0,0,0,0.3);
	-moz-box-shadow: 3px 9px 3px -6px rgba(0,0,0,0.3);
	box-shadow: 3px 9px 3px -6px rgba(0,0,0,0.3);
	border: 2px solid #d2d3d4;
	margin-bottom:30px;
	padding-bottom: 20px;

	a:hover {
		text-decoration: none;
	}
	a {
		color: #737a7f;
	}
	.news-grid-descr {
	    text-align: center;
		height: 90px;
		padding: 20px 10px;
	}
}

.fr-manufacturer-teaser {
	a {
		color: #737a7f;
		h3 {
			text-transform: uppercase;
			font-size: 18px;
			text-align: center;
		}
	}
	a:hover {
		color: #585858;
		text-decoration: none;
	}
	img {
		display:inline-block;
	}
	text-align: center;
	border-left: 1px solid #d4dbe1;
	.field-name-field-mf-logo {
		height: 130px;
	}
}
.fr-manufacturer-teaser:first-child {
	border-left: 0px;
}

