.sv-news-teaser {
	border: 1px solid #d2d3d4;
	border-bottom: 3px solid #d2d3d4;
	border-radius:2px;
	margin-bottom:30px;
	text-align:center;
	font-weight: 200;	
	h3 {
		font-size:18px;
		font-weight:700;
		color:#737a7f;
		a {
			height:47px;
			text-decoration:none;
		}
		padding-top:15px;
		padding-bottom:15px;
	}
	p {
		color:#737a7f;
		font-size:15px;
		margin-bottom: 15px;
	}
	.sv-news-description {
		padding:5px;
		padding-top:0px;
		padding-bottom:20px;
		.sv-title {
			height: 56px;
		}
		.sv-descr {
			height:80px;
			padding: 0 20px;
		}
	}
	.link-read-more {
		font-weight: 400;
	}
}
@media screen and (max-width: 767px) {
	.sv-news-teaser {
		.img-responsive {
			margin: 0 auto;
		}
	}
}

.view-news-categories {
	li > ul {
		margin-left: 10px;
	}
}
.node--news--full {
	img {
		margin: 5px 15px;
		float: left;
	}
	p > img {
		margin-bottom: 0px;
	}
}
