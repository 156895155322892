.sv-category-teaser {
	position: initial;
	border: 1px solid #d2d3d4;
	border-bottom: 3px solid #d2d3d4;
	border-radius:2px;
	padding:20px;
	margin-bottom:30px;
	text-align:center;
	h3 {
		font-family: 'Roboto Condensed', sans-serif;
		font-size:15px;
		font-weight:bold;
		padding:5px 0px;
    	text-transform: uppercase;
		a {
			text-decoration:none;
		}
	}
	img {
		margin-left: auto;
	    margin-right: auto;
	    // -webkit-filter: grayscale(1) opacity(0.5);
	    // filter: grayscale(100%) opacity(50%);
	}
	a {
		font-size:13px;
	}
	&:hover {
		a {
			color: #585858;
		}
	}
}

.fa-angle-right {
	margin-left: 10px;
}

.fa-padding-right.fa-angle-right {
	margin-left: 0px;
	margin-right: 10px;

}

// #block-bean-car-paintings, #block-bean-industrial-paintings {
// 	.slick-wrapper, .slick__slider, .slick--slider {
// 		height: 666px;
// 	}
// 	.slick-slide {
// 		margin: 0 -15px;
// 		.slick-media {
// 			padding: 15px;
// 		}
// 	}
// 	.slick-arrow::before {
// 		color:#d2d3d4;
// 	}
// }
