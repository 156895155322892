.navbar-sv {
    margin-bottom: 0px;
    border: 0px;
    box-shadow: 1px -1px 5px #888888;
    border-radius: 0px;
    .navbar-header {
        display: table-row;
        height: 110px;
        width: 100%;
        padding: 12px 0 0;
        .logo {
            display: table-cell;
            padding-right: 15px;
            img {
                max-height: 74px;
                width: auto;
            }
        }
        .hd-responsive-container {
            display: table-cell;
            padding-top: 38px;
            width: 100%;
            .hd-search {
                float: left;
                color:#c0c0c0;
                display: table;
                padding-left: 2px;
                width:50%;
                z-index: 1;
                .dropdown {
                    top: 37px;
                    width:330px;
                    .dropdown-menu {
                        top: 0px;
                        width: 100%;
                    }
                }
                .input-group, form {
                    display: block;
                    text-align: center;
                }
                .input-group-addon {
                    display:none;
                }
                label.control-label {
                    display:none;
                }
                .form-group {
                    margin-bottom: 0px;
                }
                input {
                    height: 40px;
                    width:330px;
                    padding: 0px 35px 0 15px;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
                .fa-search {
                    position: relative;
                    left: -30px;
                    top: -1px;
                }
                .input-group-btn {
                        position: relative;
                        left: -50px;
                        top: 5px;
                        display: inline-block;
                         z-index: 20;
                    .btn-primary {
                        color:#8e979d;
                        padding:6px;
                        background:transparent;
                        border:0px;
                    }
                }
                #edit-actions {
                    margin: 0px;
                }
            }
            .hd-work-time {
                font-family: 'Roboto Condensed', sans-serif;
                font-size: 18px;
                font-weight: 400;
                text-align: center;
                width: 25%;
                float: left;
                color: #909090;
                margin-top: 8px;
            }
            .hd-phone-number {
                font-family: 'Roboto Condensed', sans-serif;
                width: 25%;
                float: left;
                margin-top: 0px;
                letter-spacing: -1px;
                font-size: 18px;
                font-weight: 400;
                text-align: center;
                color: #909090;
                a {
                    color: #909090;
                }
                .fa {
                    font-size: 20px;
                    vertical-align: middle;
                }
                .fa.fa-envelope-o {
                    font-size: 17px;
                    display: inline;
                    margin-right: 5px;
                }
            }
        }
        .hd-language {
            display: table-cell;
            min-width: 120px;
            text-align: right;
            vertical-align: middle;
            color: #8e979d;
            padding-top: 30px;
            .filter-option {
                color: #8e979d;
                font-weight: 400;
            }
            .btn.dropdown-toggle, .btn.dropdown-toggle:active, .btn.dropdown-toggle:focus, .btn.dropdown-toggle:hover, .btn.dropdown-toggle:active:hover {
                float: right;
                -webkit-box-shadow: none;
                box-shadow: none;
            }
            #lang_dropdown_form_language, #lang_dropdown_form_language > div {
                display:table;
                float: right;
                color:#8e979d;
                .form-item-lang-dropdown-select {
                    display:table-cell;
                }
                img {
                    width:20px;
                }
            }
            a {
                color:#8e979d;
                text-decoration:none;
                cursor:pointer;
            }
        }
    }
    .navbar-collapse {
        .secondary {
            float:right;
        }
        .navbar-nav > li > a {
                font-weight: 700;
        }
        .navbar-nav
        {
            margin-left: -15px;
            li a {
                text-transform: uppercase;
                color:#fff;
            }
            .yamm-content a:hover {
                background-color:transparent;
                color:#9E9E9E;
            }
            li a:hover {
                background-color:transparent;
                color:#E0E0E0;
            }
            li ul {
                li {
                    a {
                        color: #676767;
                    }
                    // border-bottom: 1px solid #E0E0E0;
                }
                li:last-child {
                    border-bottom: 0px;
                }
            }
        }
        .navbar-nav.secondary {
            li a .fa-cart-bordered {
                border: 1px solid #fff;
                padding: 7px;
                border-radius: 3px;
                margin: -7px;
            }
            li a:hover .fa-cart-bordered {
                border: 1px solid #E0E0E0;
            }
        }
    }
    .nav>li>a:focus, .nav>li>a:hover {
        text-decoration: none;
        background-color: transparent;
    }
    .nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
        background-color: #e5808b;
    }
    .navbar-nav>li>.dropdown-menu {
        margin-top: -1px;
    }
    .nav>li>a {
        padding: 15px 10px;
    }
    .btn-transparent {
        font-weight: 400;
    }
}
.cart-image {
    padding: 11px 10px;
    float:right;
    a .fa-shopping-cart {
        border: 1px solid #fff;
        border-radius: 3px;
        color: #fff;
        padding: 5px 7px;
    }
}
.lang-dropdown-select-element {
    display:none;
}

.hd-responsive-container section.block-boxes {
    padding-bottom: 0px;
}
.bootstrap-select .btn-transparent.dropdown-toggle {
    .dropdown-menu a:focus,
    .dropdown-menu a:active,
    &:focus,
    &:active {
        outline: none;
    }
}
.yamm .yamm-content {
    padding: 10px 21px;
    .sv-menu-col {
        margin-bottom: 10px;
    }
    ul.sv-sub-menu {
        list-style: initial;
        margin-left: 15px;
    }
}
.yamm-content .sv-menu-col ul.sv-sub-menu > li > a {
    text-transform: inherit;
}

header section {
    padding: 0px;
}
a {
    color:#8e979d;
}
#edit-actions {
    margin-bottom: 0px;
}
#search-block-form .form-group {
    margin-bottom: 0px;
}
// Search autocomplete fix
.search-api-autocomplete-suggestion {
    position: static;
    overflow: hidden;
    margin-right: 10px;
    .autocomplete-suggestion-results {
        position: absolute;
        right: 10px;
    }
}

// Header font in ru and uk versions
.i18n-ru, .i18n-uk {
    .navbar-sv .navbar-collapse .navbar-nav>li>a {
        font-size: 13px;
    }
}
