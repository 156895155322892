/* Mobile devices (767px and less) */
@media (max-width: 767px) {
	body {
		/* background:yellow; */
	}
	section {
	    padding-top: 30px;
	    padding-bottom: 35px;
	}
	.sv-table .table-responsive table tbody>tr>td {
		padding-right: 5px;
		padding-left: 5px;
	}
	#views-form-order-items-form-manager-default input {
		padding-right: 5px;
		padding-left: 5px;
	}
	#edit-quantity {
		padding-right: 2px;
		padding-left: 4px;
	}
	.front-category > .container{
		padding-left: 0px;
		padding-right: 0px;
	}
	.front-category {
		background:#d44554;
		height: 100%;
		.container .ft-category-wrap {
		    position: relative;
		    bottom:0px;
		    .ft-category {
			    -moz-background-size: cover;
			    background-size: cover;
			    min-height: 350px;
			    margin-left: -15px;
			    margin-right: -15px;
		    }
		    h2 {
		    	text-shadow: 2px 1px 5px rgba(0, 0, 0, 1);
		    }
		    h3 {
		    	color:#fff!important;
		    	text-shadow: 2px 1px 5px rgba(0, 0, 0, 1);
		    }
		    .ft-category-list {
		    	margin-bottom:30px;
		    }
		    .ft-category:first-child {
			    background: url(../img/front-background.png) no-repeat;
			    background-position: left -200px center;

		    }
		    .ft-category:last-child {
			    background: rgba(255,255,255,0.5), url(../img/front-background.png) no-repeat;
			    background-position: right -250px top;
		    }
		}
	}

	// Checkout cart login
	.block-commerce-extra-plus {
	    width: 100%;
	    margin-right: 0;
	    border-left: 0px;
	    margin-bottom: 30px;
	    h3 {
	    	font-size: 26px;
    		text-align: center;
    		margin-left: 0px;
	    }
	}
	.not-logged-in.page-checkout #commerce-checkout-form-login  {
		margin-left: 0;
	    width: 100%;
	    h3 {
	    	font-size: 26px;
    		text-align: center;
	    }
	}
	.sv-register-form .form-margin {
	    margin-left: 0;
	    width: 100%;
	}
	.sv-register-form .form-item.checkbox {
	    margin-left: 0;
	}

	// Filters

	.sorting-page  .view-filters.exposed-bottom {
	    position: inherit;
	    right: 0;
	}
	.sorting-page .view-filters.exposed-top {
	    position: relative;
	    right: 0;
	    top: 0;
	    margin-bottom: 30px;
	}
	.sm-text-center {
		text-align: center;
	}

	/* Footer */
	#block-menu-menu-info-footer-menu,
	#block-menu-menu-main-footer-menu {
		ul {
			display: inline-block;
			text-align: center;
			&.menu {
				display: block;
			}
			& > li {
				margin-left: 15px;
				position: relative;
				a {
					display: inline-block;
				}
				.fa {
					position: absolute;
					left: -15px;
					top: 2px;
				}
			}

		}
	}
	#block-menu-menu-main-footer-menu {
		ul.menu {
			display: inline-block;
		}
	}
	.questionpro-modal {
		.modal-dialog,
		.modal-body {
			max-width: 310px;
			padding: 0;
		}
	}
	.modal-special-offer-modal {
		.inner-cart {
			min-height: 0;
			padding: 10px;
		}

		.col-1 {
			padding: 15px 0;
		}

		.modal-dialog {
			width: 100% !important;
			max-width: 440px;
			.modal-body {
				width: 100% !important;
			}
		}
		.view-special-offer-list {
			.view-content {
				text-align: center;
			}
		}
		.views-field-title {
			min-height: 69px;
		}
	}
}

/* Small devices (tablets, 768px and up) */
@media (max-width: 991px) {
	body {
		/* background:red; */
		margin: 0px;
	}
	.main-wrap .front-category {
		height: 100%;
		.container .ft-category-wrap {
		    position: relative;
		    bottom:0px;
		    .ft-category {
			    -moz-background-size: cover;
			    background-size: cover;
			    min-height: 350px;
			    margin-left: 0px;
			    margin-right: 0px;
			    padding-top:35px;
			    padding-bottom:15px;
			  	h2 {
		    		text-shadow: 2px 1px 5px rgba(0, 0, 0, 1);
			  	}
			    h3 {
			    	color:#fff!important;
			    	text-shadow: 2px 1px 5px rgba(0, 0, 0, 1);
			    }
		    }

		    .ft-category-list {
		    	margin-bottom:30px;
		    	padding-left:0px;
		    	padding-right:0px;
		    }
		}
	}
	/* Header */
	button.navbar-toggle {
		display:block;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		margin-top: 42px;
	}
	.navbar-sv .navbar-header {
	    display: block;
	}

	.container>.navbar-header {
	    margin-right: 0px;
	    margin-left: 0px;
	}
	.navbar-sv .navbar-header .hd-language {
		float:left;
		min-width: 60px;
		margin-top: 10px;
		.filter-option {
			display:none!important;
		}
	}
	.sv-collapse {
	    border-bottom: 3px solid #d44554;
   		padding-bottom: 10px;
		background-color: #e5e5e5;
		a,
		.fa {
			color: #737a7f !important;
		}
		.fa {
			border-color: #737a7f !important;
		}
		.hd-responsive-container {
			display: block!important;
			label.control-label, .input-group-addon {
				display: none;
			}
			form {
			    display: table;
				margin: 0 auto;
			    width: 100%;
			    div.form-type-textfield, .input-group-btn {
			    	display: table-cell;
			    }
			    .input-group {
			    	width: 100%;
			    }
			}
			.hd-search {
				padding-top:20px;
				input {
					width:100%;
					height: 34px;
					padding-left: 5px;
				}
				.btn {
					-webkit-border-radius: 0;
					-moz-border-radius: 0;
					border-radius: 0;
				}
			}
			.hd-work-time, .hd-phone-number {
				width:50%;
				float:left;
				text-align:center;
				color:#fff;
			}
		}
		.hd-responsive-container:after {
		    display: table;
		    content: " ";
		    clear:both;
		}
		nav {
			text-align:center;
			.dropdown-menu {
				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;

				border-bottom: 3px solid #d2d3d4;
			}
		}
		.hd-language.dropdown {
			width:50%;
			float:left;
			margin-top: 12px;
			a.dropdown-toggle {
				color:#fff;
			}
		}
		.navbar-nav.secondary {
			width:50%;
			float:right;
			margin: 0px;
    		text-align: right;
			li {
				display:inline-block;
				float: right;
			}
		}
	}
	.dropdown-menu, .dropdown {
		position: relative;
		width: 100%;
	}
	.sv-collapse {
		&.navbar-collapse.collapse {
			display:none!important;
		}
		nav {
			& > .menu {
				width: 100%;
				li {
					float: none;
					width: 100%;
					a {
						padding: 15px 5px 5px;
					}
				}
			}
		}
	}
	.sv-collapse.navbar-collapse.collapse.in {
		display:block!important;
	}
	.hd-work-time, .hd-phone-number {
		display:none;
	}
	/* News */
	.fr-manufacturer-teaser {
		border:0px;
		margin-bottom:30px;
	}
}

@media (min-width: 500px) and (max-width: 991px) {
	.sv-collapse {
		.hd-responsive-container {
			form {
				width: 55%;
			}
		}
	}
}

/* Medium devices (desktops, 992px and up) */
@media (max-width: 1200px) {
	body {
		/* background:green; */
	}
	.front-category .container .ft-category-wrap .ft-category h2 {
		font-size: 22px;
	}
	.navbar-collapse .nav>li>a {
		padding: 15px 10px;
	}
	.navbar-sv .navbar-header {
		padding: 15px 0;
	    .logo img {
	    	padding: 3px 0px;
    		margin-top: 5px;
	    }
	}
}

/* Large devices (992px and up) */
@media (min-width: 991px) {
	.front-category {
		margin-bottom: 110px;
		.container .ft-category-wrap .ft-category h3 {
			position:absolute;
			left: 0;
			right: 0;
		}
	}
	.sv-category-teaser {
		h3 {
			min-height: 65px;
		}
	}
}
@media (min-width: 1200px) {
	.lg-text-center {
		text-align: center;
	}
	.i18n-lv .front-category .col-sm-5:first-child .block-title {
		width: 150%;
		text-align: left;
		margin-left: -25px;
	}
}
@media (min-width: 767px) {
	// Dropdown fix
	.dropdown:hover > .dropdown-menu {
	    display: block;
	}
	.sv-close {
		display:none;
	}
	.questionpro-modal {
		.modal-body {
			width: 100% !important;
		}
	}
}

.dropdown.sv-active > .dropdown-menu {
    display: block;
}


/* Only md sceen size */
@media (min-width: 991px) and (max-width: 1200px) {
	.navbar-sv .navbar-header .hd-responsive-container .hd-search {
	input, .dropdown {
		width: 250px;
	}
	}
	.navbar-sv .navbar-header .hd-responsive-container .hd-phone-number, .navbar-sv .navbar-header .hd-responsive-container .hd-work-time {
		font-size: 14px;
	}
	.navbar-collapse .nav>li>a  {
		padding: 15px 5px;
    	font-size: 13px;
	}
	nav .menu.nav.navbar-nav {
		ul.dropdown-menu.sv-submenu {
			top: 50px;
		}
	}
}

@media (min-width: 767px) and (max-width: 991px) {
	.main-wrap .front-category {
		background: url(../img/front-background.png) center center no-repeat;
		.container .ft-category-wrap {
			.ft-category:last-child, .ft-category:first-child {
				background: transparent;
			}
		}
	}
	.navbar-collapse .nav>li>a  {
		padding: 15px 5px;
    	font-size: 13px;
	}
	nav .menu.nav.navbar-nav {
		ul.dropdown-menu.sv-submenu, ul.dropdown-menu {
			position: absolute;
			top: 100px;
		}
	}
	.modal-special-offer-modal {
		.modal-dialog {
			width: 100% !important;
			max-width: 700px;
		}
		.view-special-offer-list {
			.col-md-6 {
				max-width: 50%;
				float: left;
			}
		}
	}
}
/* Only md sceen size */
@media (max-width: 450px) {
	.navbar-sv .navbar-header .logo {
	    width: 60%;
	    margin-top: 0px;
	}
	.modal-special-offer-modal {
		.modal-dialog {
			width: 100% !important;
			max-width: 400px;
			.modal-body {
				width: 100% !important;
			}
		}
	}
}
@media (max-width: 410px) {
	.checkout.btn-red {
			margin-top: 10px;
	}
	.listing-form {
			margin-top: 0;
	}
	.outer-bottom {
			margin-bottom: 20px;
	}
	.modal-special-offer-modal {
		.modal-dialog {
			width: 100% !important;
			max-width: 390px;
			.modal-body {
				width: 100% !important;
			}
		}
	}
 }
@media (max-width: 400px) {
	.navbar-sv .navbar-header .logo {
	    width: 50%;
	    margin-top: 5px;
	}
	.listing-form .form-type-numberfield {
  	  padding: 0 7px !important;
	}
	.modal-special-offer-modal {
		.modal-dialog {
			width: 100% !important;
			max-width: 310px;
			.modal-body {
				width: 100% !important;
			}
		}
	}
}
@media (max-width: 300px) {
	.navbar-sv .navbar-header {
		padding: 15px 0;
		.logo {
		    width: 100%;
		}
	}
}

/* ---------------------------------------------------------------------------
							DOVYDO RESPONSAI
------------------------------------------------------------------------------*/

@media (max-width: 767px) {
	#footer {
		.btn-margin {
			float: none !important;
			width: auto;
			padding: 7px 40px;
			margin: 0 auto;
		}

		.footer-block {
			padding-bottom: 35px;
		}

		h4 {
			text-align: center;
			padding-bottom: 5px;
		}

		#block-menu-menu-info-footer-menu {
			text-align: center;
			padding-bottom: 0;
			.btn-red.btn-margin {
			    float: none;
			}
		}

		#block-menu-menu-main-footer-menu {
			text-align: center;
			padding-bottom: 15px;
			padding-top: 15px;
		}
	}


	.sv-manufacturers-page {
		.view-content {
			.views-row{
				img {
					margin: -20px auto 5px;
				}
			}
		}
	}
}


    @media (min-width: 1200px) {
        .footer-contact-number {
            float: right;
            width: auto!important;
            padding: 0;
        }
        .footer-main-menu {
            padding-top: 40px;
        }
    }

/*@media (max-width: 992px) {
	#footer .newsletter-subscription-btn {
		padding: 7px 0px;
	}
}*/

@media (min-width: 500px) and (max-width: 767px) {
	.region-footer-left, .region-footer-center, .region-footer-right {
		form {
			width: 50%;
			margin: 0 auto;
		}
	}
}

@media screen and (max-width: 767px) {
	.table-responsive {
		border: 0;
	}

	.boxes-box-content p,
	#block-entityform-block-newsletter-subscription .content p {
		text-align: center;
	}
}

.table>tbody>tr>td, .table>tfoot>tr>td{
	vertical-align: middle;
}
@media screen and (max-width: 600px) {
	table#cart tbody td .form-control{
		width:20%;
		display: inline !important;
		text-align: center;
	}
	.actions .btn{
		width:36%;
		margin:1.5em 0;
	}

	.actions .btn-info{
		float:left;
	}
	.actions .btn-danger{
		float:right;
	}

	table#cart {
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
	}

	table#cart thead { display: none; }
	table#cart tbody td { display: block; padding: .6rem; min-width:320px; border-top: 1px solid #ddd;}
	table#cart tbody tr td:first-child { background: #F5F5F5; color: #737a7f; }
	table#cart tbody td:before {
		content: attr(data-th); font-weight: bold;
		display: inline-block; width: 8rem;
	}

	table#cart .views-field-line-item-title {
		border-left: 0;

		img {
			display: none;
		}
	}

	table#cart .views-field-edit-delete {
		text-align: center;
		border-right: 0;
	}

	table#cart .views-field-edit-delete:before {
		content: none;
	}

	table#cart .views-field-edit-delete button {
		padding: 5px 25px;
	}

	table#cart tfoot td{display:block; }
	table#cart tfoot td .btn{display:block;}
}
