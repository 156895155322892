/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$s-checked-name: 's-checked';
$s-checked-x: 28px;
$s-checked-y: 66px;
$s-checked-offset-x: -28px;
$s-checked-offset-y: -66px;
$s-checked-width: 18px;
$s-checked-height: 11px;
$s-checked-total-width: 97px;
$s-checked-total-height: 82px;
$s-checked-image: '../img/sprites.png';
$s-checked: (28px, 66px, -28px, -66px, 18px, 11px, 97px, 82px, '../img/sprites.png', 's-checked', );
$s-email-name: 's-email';
$s-email-x: 0px;
$s-email-y: 66px;
$s-email-offset-x: 0px;
$s-email-offset-y: -66px;
$s-email-width: 23px;
$s-email-height: 16px;
$s-email-total-width: 97px;
$s-email-total-height: 82px;
$s-email-image: '../img/sprites.png';
$s-email: (0px, 66px, 0px, -66px, 23px, 16px, 97px, 82px, '../img/sprites.png', 's-email', );
$s-error-icon-name: 's-error-icon';
$s-error-icon-x: 0px;
$s-error-icon-y: 0px;
$s-error-icon-offset-x: 0px;
$s-error-icon-offset-y: 0px;
$s-error-icon-width: 28px;
$s-error-icon-height: 28px;
$s-error-icon-total-width: 97px;
$s-error-icon-total-height: 82px;
$s-error-icon-image: '../img/sprites.png';
$s-error-icon: (0px, 0px, 0px, 0px, 28px, 28px, 97px, 82px, '../img/sprites.png', 's-error-icon', );
$s-info-icon-name: 's-info-icon';
$s-info-icon-x: 33px;
$s-info-icon-y: 0px;
$s-info-icon-offset-x: -33px;
$s-info-icon-offset-y: 0px;
$s-info-icon-width: 28px;
$s-info-icon-height: 28px;
$s-info-icon-total-width: 97px;
$s-info-icon-total-height: 82px;
$s-info-icon-image: '../img/sprites.png';
$s-info-icon: (33px, 0px, -33px, 0px, 28px, 28px, 97px, 82px, '../img/sprites.png', 's-info-icon', );
$s-name-name: 's-name';
$s-name-x: 33px;
$s-name-y: 33px;
$s-name-offset-x: -33px;
$s-name-offset-y: -33px;
$s-name-width: 21px;
$s-name-height: 20px;
$s-name-total-width: 97px;
$s-name-total-height: 82px;
$s-name-image: '../img/sprites.png';
$s-name: (33px, 33px, -33px, -33px, 21px, 20px, 97px, 82px, '../img/sprites.png', 's-name', );
$s-password-name: 's-password';
$s-password-x: 66px;
$s-password-y: 30px;
$s-password-offset-x: -66px;
$s-password-offset-y: -30px;
$s-password-width: 21px;
$s-password-height: 27px;
$s-password-total-width: 97px;
$s-password-total-height: 82px;
$s-password-image: '../img/sprites.png';
$s-password: (66px, 30px, -66px, -30px, 21px, 27px, 97px, 82px, '../img/sprites.png', 's-password', );
$s-success-icon-name: 's-success-icon';
$s-success-icon-x: 0px;
$s-success-icon-y: 33px;
$s-success-icon-offset-x: 0px;
$s-success-icon-offset-y: -33px;
$s-success-icon-width: 28px;
$s-success-icon-height: 28px;
$s-success-icon-total-width: 97px;
$s-success-icon-total-height: 82px;
$s-success-icon-image: '../img/sprites.png';
$s-success-icon: (0px, 33px, 0px, -33px, 28px, 28px, 97px, 82px, '../img/sprites.png', 's-success-icon', );
$s-warning-icon-name: 's-warning-icon';
$s-warning-icon-x: 66px;
$s-warning-icon-y: 0px;
$s-warning-icon-offset-x: -66px;
$s-warning-icon-offset-y: 0px;
$s-warning-icon-width: 31px;
$s-warning-icon-height: 25px;
$s-warning-icon-total-width: 97px;
$s-warning-icon-total-height: 82px;
$s-warning-icon-image: '../img/sprites.png';
$s-warning-icon: (66px, 0px, -66px, 0px, 31px, 25px, 97px, 82px, '../img/sprites.png', 's-warning-icon', );
$spritesheet-width: 97px;
$spritesheet-height: 82px;
$spritesheet-image: '../img/sprites.png';
$spritesheet-sprites: ($s-checked, $s-email, $s-error-icon, $s-info-icon, $s-name, $s-password, $s-success-icon, $s-warning-icon, );
$spritesheet: (97px, 82px, '../img/sprites.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Here are example usages in HTML:

`display: block` sprite:
<div class="icon-home"></div>

`display: inline-block` sprite:
<img class="icon-home" />
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
@mixin multiline-truncate ($font-size, $line-height, $lines-to-show) {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 100%;
  max-height: $font-size * $line-height * $lines-to-show; /* Fallback for non-webkit */
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}