.sv-sidebar {
	border: 1px solid #d2d3d4;
	border-bottom: 3px solid #d2d3d4;
	border-radius:2px;
	padding:0px 15px;
	margin-top:40px;
	margin-bottom:30px;
	section {
	    padding-top: 20px;
	    padding-bottom: 20px;
	}
	section#block-views-product-sub-categories-block {
		border-bottom: 1px solid #d2d3d4;
	}
	ul, ul.nav {
		a {
			text-decoration:none;
			padding: 0px;
		}
		li {
			margin-bottom:5px;
		}
		li:last-child {
			margin-bottom:0px;
		}
		li>a:hover, li>a:focus {
			background-color:transparent;
		}
	}
	.view-content {
		a {
			text-decoration:none;
		}
	}
	.nav>li>a:focus, .nav>li>a:hover {
			background-color:transparent;
	}
	h3 {
		margin-bottom:10px;
	}
	.active-trail, .active-trail .fa, .active {
		font-weight: 500;
    	color: #585858;
	}
}

.fa-angle-left {
	margin-right: 10px;
}

.product-teaser {
	position: initial;
	border: 1px solid #d2d3d4;
	border-bottom: 3px solid #d2d3d4;
	border-radius:2px;
	padding:15px;
	margin-bottom:30px;
	img {
		margin: 0 auto;
	}
	h3 {
		font-size:14px;
		height:52px;
		line-height: 21px;
		display:block;
		text-align:center;
		// display: table;
  		// width: 100%;
		a {
			height:52px;
			&:hover {
				text-decoration: none;
			}
			// display: table-cell;
		 	// vertical-align: middle;
		}
	}
	.product-teaser-old-price {
		height: 18px;
		text-align: center;
		text-decoration: line-through;
	}
	.product-teaser-main-price {
		font-size:30px;
		text-align:center;
		padding: 10px 0px 20px 0;
		height: 52px;
		.from_price {
			font-size: 20px;
		}
	}
	.product-teaser-compare {
		text-align:center;
		font-size:13px;
		margin-top:10px;
		min-height: 23px;
		.fa-exchange {
			margin-right:5px;
		}
	}
	.form-group {
	    margin-bottom: 0px;
	}
}

.product-teaser-discount {
	background:#d44554;
	position:absolute;
	right:12px;
	top:20px;
	color:#fff;
	padding:2px 20px;
	border-radius:2px;
	font-size:19px;
	font-weight: 400;
}
.facetapi-facetapi-links  {
	.facetapi-inactive .fa {
    	margin-right: 10px;
	}
	.facetapi-active .fa {
	    margin-right: 4px;
	}
}
// Slider style
.yui3-g {
	// .range-box input {
	// 	display: none;
	// }
	.ui-slider-horizontal {
		height: 4px;
		border: 0px;
		background:#ccc;
		margin-bottom: 15px;
	    .ui-slider-handle {
	    	background: #fff;
	    	top: -5px;
	    	border-radius: 15px;
		    background: #fff;
		    border: 2px solid #d44554;
		    height: 15px;
		    width: 15px;
			&:focus {
				outline: none;
			}
	    }
	    .ui-slider-handle:hover, .ui-slider-handle:active {
		    background: #ccc;
	    }
		.ui-widget-header {
			background: #d44554;
		}
	}
	.price-label {
		font-size: 13px;
		margin-top: 6px;
	}
	.range-box {
		font-size: 13px;
		.form-item-range-from, .form-item-range-to {
		  position: relative;
		}
		.form-item-range-from input , .form-item-range-to input {
		  padding-right: 18px;
		}
		.form-item-range-from:after , .form-item-range-to:after {
		  position: absolute;
		  top: 6px;
		  right: 6px;
		}
	}
}
.search-api-ranges-widget button.btn.btn-default.form-submit {
	display: none;
}


// Sort by style
.block-search-api-sorts {
	.block-label, .search-api-sorts {
		display:inline-block;
		font-weight: 400;
	}
	ul > li {
		display:inline-block;
	}
	.search-api-sorts {
		li {
			border: 1px solid #d2d3d4;
		    padding: 7px 15px;
		    margin: 0 5px;
		}
	}
	.search-api-sort-active {
		img {
			display: inline-block;
    		margin-left: 10px;
		}
	}
}

// Exposed filters
.exposed-bottom {
	#edit-field-product-commerce-price-amount-decimal-wrapper, #edit-field-product-commerce-price-amount-decimal-1-wrapper {
		display:none;
	}
}
.exposed-top {
	.views-widget-per-page, .views-submit-button {
		display:none;
	}
}
.view-filters.exposed-bottom {
    position: absolute;
    right: -10px;
    margin-top: 25px;
    .form-type-select {
    	display: table;
    	label, select {
    		display: table-cell;
    	}
    	label {
    		padding-right: 15px;
    		font-weight: 400;
    	}
    }
    .views-exposed-widget.views-widget-per-page {
    	padding: 0px 6px 0 0;
    }
    .dropdown-toggle {
    	font-style: normal;
    }
}
.sorting-page .view-filters.exposed-top {
	top: -80px;
}
.view-filters.exposed-top {
    position: absolute;
    right: -10px;
    top: -65px;
    #edit-field-product-commerce-price-amount-decimal-wrapper, #edit-field-product-commerce-price-amount-decimal-1-wrapper {
    	label {
    		display: inline-block;
    		margin-right: 5px;
    		font-weight: 400;
    	}
    	.views-widget {
    		display: inline-block;
    		max-width: 50px;
    	}
    }
    .views-exposed-widget {
    	padding: 0 10px 0 0;
    }
}
.view-browse-products, .view-browse-products-by-manufacturer {
	position: relative;
}

.sv-sorting-block {
	display: inline-block;
    margin-top: 40px;
}
