#footer {
    .footer-inside {
        padding: 10px 15px;
        font-size: 14px;
        p {
        	margin:0px;
        }
    }
    h4 {
    	color:#323d46;
        padding-bottom: 10px;
        line-height: 30px;
    }
    .region-footer-left, .region-footer-center, .region-footer-right {

        .nav {
            .fa-angle-left {
                margin-right:10px;
            }
        }
        .nav>li>a {
            padding: 0px;
            padding-bottom: 10px;
            line-height: 20px;
            font-weight: 400;
            color: #737a7f;
        }

        .newsletter-subscription {
            border-radius: 0px;
            display: inline-block;
            float: left;
            width: 70%;
            border-right: 0px;
        }

        .block {
            padding-bottom: 10px;
        }

        .icon-color {
            color: #323d46;
            font-size: 20px;
        }

        .footer-contact-number {
            float: left;
            width: 100%;
            .fa {
                    border: 2px solid;
                    border-radius: 15px;
                    padding: 4px 3px 2px 3px;
                    font-size: 13px;
                    font-weight: bold;
                    line-height: 0.8;
            }
        }

        .block-menu {
            font-size: 14px;
            padding-bottom: 20px;
        }
        #block-menu-menu-info-footer-menu {
            padding-bottom: 10px;
        }

        .social-icons {
            padding-top: 20px;
        }

        .newsletter-subscription-btn {
            border-radius: 0px;
            width: 30%;
            float: right;
            padding: 7px 0px;
        }

        .btn-margin {
            float: left;
            margin-top: 10px;
        }

        .nav>li>a:focus, .nav>li>a:hover {
            background-color: transparent;
            color: #585858;
        }

        .first.icon-color {
            margin-left: 10px;
        }
    }
    section {
        padding-bottom:0px;
    }
}
