
.sv-manufacturers-page {
	.view-content {
		overflow: hidden;
		.views-row{
			// border-right: 1px solid #d2d3d4;
			// border-top: 1px solid #d2d3d4;
			border: 1px solid #d2d3d4;
			padding: 25px;
			padding-bottom: 5px;
			height: 225px;
		    margin: 0 0 -1px -1px;
		    border-top: 0;
			overflow: hidden;
			h3 {
	    		text-transform: uppercase;
	    		font-size: 18px;
	    		text-align: center;
			}
			img {
				margin: 0 auto;
				margin-bottom: 5px;
			}
			a:hover {
				text-decoration: none;
			}
		}
		.views-row:last-child {
			border-right: 0px;
		}
	}
}

@media (min-width: 992px) {
	.sv-manufacturers-page {
		.view-content {
			.views-row:nth-child(-n+6) {
				border-top: 0px;
			}
			.views-row:nth-child(6n+0) {
				border-right: 0px;
			}
		}
	}
}
@media (min-width: 768px) and (max-width: 992px) {
	.sv-manufacturers-page {
		.view-content {
			.views-row:nth-child(-n+4) {
				border-top: 0px;
			}
			.views-row:nth-child(4n+0) {
				border-right: 0px;
			}
		}
	}
}

@media (max-width: 768px) {
	.sv-manufacturers-page {
		.view-content {
			.views-row:nth-child(-n+2) {
				border-top: 0px;
			}
			.views-row:nth-child(2n+0) {
				border-right: 0px;
			}
		}
	}
}

