
.form-control {
  border-radius: 0;
}

.form-group {
  margin-bottom: 10px;
}

.form-autocomplete {
  input {
    padding-right: 30px;
  }
}

#user-login .form-item-pass {
  margin-bottom: 5px;
}

.form-type-password-confirm.form-group {
  margin-bottom: 0px;
}

// Contact form
.sv-contact-form, .sv-register-form {
  font-weight: 100;
  .form-item {
    display: table;
    width: 100%;
    label, input {
      display: table-cell;
    }
    input {
      font-size: 13px;
      font-style: italic;
    }
    label {
      text-align: right;
      padding-right: 20px;
      vertical-align: top;
      font-size: 14px;
    }
  }
  .form-item.checkbox {
    margin-top: 0px;
    margin-bottom: 0px;
    label {
      text-align: left;
      font-size: 13px;
    }
  }
  .help-block {
    display: none;
  }
}

.form-item.form-item-field-department-und-0-value.form-type-textfield.form-group {
  display: none;
}

.sv-register-form {
  .form-item {
    label {
      width: 33.333333%;
    }
  }
  .form-item.checkbox {
    margin-left: 33.333333%;
  }
  .form-margin {
    margin-left: 33.333333%;
    width: 66.666666%;
  }
}

.sv-contact-form {
  .form-item {
    label {
      width: 16.666666666%;
    }
  }
  .form-item.checkbox {
    margin-left: 16.666666666%;
  }
  .form-margin {
    margin-left: 16.666666666%;
  }
}

.form-type-select:not(.form-item-lang-dropdown-select) {
  .dropdown-toggle {
    border-radius: 0px;
    font-style: italic;
    color: #7D7D7D;
    font-weight: 400;
    font-size: 13px;
  }
}

.commerce_payment {
  .ajax-progress.ajax-progress-throbber {
    margin-right: 5px;
  }
}

.bootstrap-select {
  .input-group-addon {
    position: absolute;
    right: 30px;
    top: 2px;
    z-index: 10;
    border-radius: 0;
    border: 0px;
    background-color: transparent;
  }
}

// Calendar field
.field-widget-date-popup {
  .form-type-date-combo > label, .form-type-textfield > label {
    display: none;
  }
  .form-type-date-popup {
    div {
      width: 100%;
    }
    width: 100%;
  }
  .container-inline-date .form-item input, .date-no-float {
    width: 100%;
  }
  .container-inline-date > .form-item {
    margin-right: 0px;
  }
  .help-block {
    font-size: 10px;
    margin-top: 0px;
    text-align: right;
    font-style: italic;
  }
}

.form-type-date-popup .help-block {
  font-size: 10px;
  margin-top: 0px;
  text-align: right;
  font-style: italic;
  float: left;
}

#edit-created-wrapper.views-exposed-widget {
  .container-inline-date {
    display: inline-block;
    width: 50%;
    .form-item-created-min, .form-item-created-max {
      width: 100%;
    }
  }
  #edit-created-min, #edit-created-max {
    width: 50%;
    float: left;
    input {
      width: 100%;
    }
    label {
      display: none;
    }
  }
  .form-item-created-min-time,
  .form-item-created-max-time {
    display: none;
  }
}

// masquerade style
#masquerade-block-1 {
  #quick_switch_links {
    li a {
      display: block;
      width: 100%;
      text-align: center;
      color: #737a7f;
      font-size: 15px;
      background-color: #f5f5f5;
      padding: 7px 30px;
      margin-top: 5px;
    }
  }
}

#stock-quantity-info {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 6px 12px;
}

.stock-quantity-wrapper {
  max-width: 95px;
  .control-label {
    text-align: center;
  }
}
.group-company,
.group-customer {
  border: 0;
  box-shadow: none;
  margin-bottom: 0;
  .panel-heading {
    display: none;
  }
  .panel-body {
    padding: 0;
  }
}
.register-type-wrapper {
  margin-left: 148px;
  .panel-body {
    padding: 0 10px;
    label {
      text-align: left;
    }
  }
}

/********Global discount container********/
.global-discount-container {
  width: 100%;
  margin: 15px auto 10px;
  .form-control {
    width: 80px;
    float: left;
    margin-right: 20px;
  }
}
.global-discount-container {
  .global-discount-lagel, .form-item-global-discount, button {
    display: inline-block;
  }
  border: 0;
  box-shadow: none;
  .global-discount-lagel {
    padding-right: 10px;
  }
  .panel-heading {
    display: none;
  }
  .panel-body {
    text-align: right;
    padding: 0;
    .btn {
      width: 160px;
    }
  }
  span {
    font-size: 13px;
    font-weight: bold;
  }
  #edit-action-svydis-custom-vbo-action {
    width: auto;
    padding: 7px!important;
    text-transform: capitalize;
    background-color: #f5f5f5;
    -moz-border-radius: 0px;
    border-radius: 0px;
    border: 0px;
    color: #737a7f;
    font-size: 15px;
    min-width: 147px;
  }
}
@media (max-width: 768px) {
  .global-discount-container {
    .panel-body {
      text-align: left;
    }
  }
}


//Listing form style
.listing-form {
  float: left;
  margin-top: 75px;
  font-size: 12px;
  text-align: center;
  .form-autocomplete {
    max-width: inherit!important;
    & + #product_info {
      bottom: 28px;
      right: 50px;
      top: inherit;
      left: inherit;
    }
  }
  .form-type-numberfield {
    padding: 0 10px !important;
  }
  .form-item-checkvat.form-group {
    padding-top: 22px;
    .form-item-checkvat.radio {
      margin: 0 !important;
    }
  }
  .no-padding .btn-default {
    margin: 27px auto 0px auto !important;
    display: block;
  }
  .add-button {
    z-index: 99;
  }
  .no-padding {
    margin-bottom: 5px;
  }
}
.listing-form-info {
  float: left;
  padding-left: 80px;
}
.user-page-listing-form {
  #add_line_item_widget {
    min-width: inherit !important;
  }
}
#views-form-order-items-form-manager-price-listing-default, #views-form-order-items-form-manager-default {
  float: left;
}
#svydis-common-create-order-form {
  .listing-form {
    .form-autocomplete {
      & + #product_info {
        bottom: 38px;
        right: 50px;
        top: inherit;
        left: inherit;
      }
    }
  }
}

@media (max-width: 992px) {
  .listing-form {
    border: 1px solid #d2d3d4;
    padding: 15px 30px;
  }
  .listing-form-info {
    border: 1px solid #d2d3d4;
    padding: 15px 15%;
    padding-right: 0;
    border-top: 0;
  }
}
