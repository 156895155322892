.btn:active, .btn:focus, .btn:active:hover, .btn:active:focus {
    background-color: #fff;
}

.btn-primary {
    background-color: #d44554;
    border-color: #d44554;
}
.btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:active:hover, .btn-primary:active:focus {
    background-color: #d44554;
    border-color: #d44554;
}
.btn-red{
	background-color: #d44554;
    color: #fff;
    padding: 7px 20px;
    text-transform: uppercase;
    border: 0px;
    height: 34px;
    border-radius: 0px;
    text-decoration: none;
    text-align: center;
    a, a:hover {
        color: #fff;
        text-decoration: none;
    }
}
.btn.btn-red:hover, .btn.btn-red:active, .btn.btn-red:focus, .btn.btn-red:active:hover, .btn.btn-red:active:focus {
   color: #fff;
   text-decoration: none;
   background-color: rgba(212,69,84,.75);
}

.btn-transparent, .btn-transparent.checkout-back {
    background-color: #fff;
    padding: 5px 0px;
    padding-right: 25px;
    padding-left: 3px;
    border:0px;
    color:#737a7f;
    font-weight: bold;
    i {
        font-weight: bold;
    }
}
.btn-transparent:hover, .btn-transparent:active, .btn-transparent:focus, .btn-transparent:active:hover, .btn-transparent:active:focus,
.btn-transparent.checkout-back:hover, .btn-transparent.checkout-back:active, .btn-transparent.checkout-back:focus, .btn-transparent.checkout-back:active:hover, .btn-transparent:active:focus
{
    background-color: #fff!important;
    color:#B3B3B3;
    text-decoration: none;
}

a:hover, a:active, a:focus {
    color: #585858;
}


.btn-red-responsive {
    width:100%;
    border-radius: 0px;
    background-color: #d44554;
    padding: 10px 0px;
    text-transform: uppercase;
    border: 0px;
    color:#fff;
    .fa-shopping-cart {
        margin-right:10px;
    }
    .ajax-progress.ajax-progress-throbber {
        margin-left: 5px;
    }
}

.btn-red-responsive:hover, .btn-red-responsive:active, .btn-red-responsive:visited, .btn-red-responsive:focus, .btn-red-responsive:active:focus, .btn-red-responsive:active:hover,  {
    border-radius: 0px;
    background-color: #d73648;
    color:#fff;
}
.btn-red-responsive.btn-default.disabled.focus, .btn-red-responsive.btn-default.disabled:focus, .btn-red-responsive.btn-default.disabled:hover, .btn-red-responsive.btn-default[disabled].focus, .btn-red-responsive.btn-default[disabled]:focus, .btn-red-responsive.btn-default[disabled]:hover, fieldset[disabled] .btn-red-responsive.btn-default.focus, fieldset[disabled] .btn-red-responsive.btn-default:focus, fieldset[disabled] .btn-red-responsive.btn-default:hover,
.btn-red-responsive.btn-success[disabled]:hover, .btn-red-responsive.btn-success[disabled]:focus {
    border-radius: 0px;
    background-color:rgba(212, 69, 84, 0.75);
    color:#fff;
}

// Cart buttons
.btn-gray {
    background-color: #f5f5f5;
    border-radius: 0px;
    border:0px;
    color:#737a7f;
    text-transform: uppercase;
    font-size:15px;
    padding: 7px 30px;
}
.btn-gray:hover, .btn-gray:active, .btn-gray:focus, .btn-gray:active:hover, .btn-gray:active:focus,
.btn-gray.checkout-back:hover, .btn-gray.checkout-back:active, .btn-gray.checkout-back:focus, .btn-gray.checkout-back:active:hover, .btn-transparent:active:focus
{
    background-color: #e6e6e6;
    border:0px;
    color:#737a7f;
}
.btn-lg {
    padding: 10px 90px;
}
.btn-sm {
    width: auto;
    padding: 7px;
    text-transform: capitalize;
}
.btn-minus, .btn-minus:hover {
    padding: 5px;
    background-color: #FBFBFB;
    border-left: 2px solid #F3F3F3;
    border-right: 2px solid #F3F3F3;
    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
    a {
        text-decoration: none;
    }
}
.commerce-quantity-plusminus-link a {
    &:hover {
        padding: 6px 7px;
    }
    &:focus {
        text-decoration: none;
    }
}
.commerce-quantity-plusminus-link-decrease a:hover {
    -khtml-border-radius: 3px 0px 0px 3px;
    -moz-border-radius: 5px 0px 0px 5px;
    -webkit-border-radius: 3px 0px 0px 3px;
    border-radius: 3px 0px 0px 3px;
}
.commerce-quantity-plusminus-link-increase a:hover {
    -khtml-border-radius: 0px 3px 3px 0px;
    -moz-border-radius: 0px 5px 5px 0px;
    -webkit-border-radius: 0px 3px 3px 0px;
    border-radius: 0px 3px 3px 0px;

}

.btn-bordered, .btn-bordered:hover {
    border: 1px solid #d2d3d4;
}

.btn-danger:active,
.btn-danger:focus {
    outline: none;
}

// Button sizes
.btn-sm {
    padding: 7px 0px;
    text-align: center;
    font-size: 13px;
    text-decoration: none;
}
.btn-sm:hover {
    cursor: pointer!important;
}

.map-btn {
    cursor: pointer;
    margin-top: 10px;
    font-size: 13px;
    font-weight: 400;
    .fa {
        margin-right: 5px;
    }
}
.map-btn:hover {
    color:#8e979d;
}

.link-arrow-right {
    width:100%;
    text-align:right;
    margin-top: 30px;
    a {
        font-size:15px;
        text-transform: uppercase;
        color:#737a7f;
        .fa-angle-right {
            margin-left:20px;
        }
    }
    a:hover {
        text-decoration: none;
        color: #585858;
    }
}
.link-read-more {
    text-decoration: none;
}
.link-read-more:hover {
    color:#d73648;
    text-decoration: none;
}
.link-forgot-pass {
    font-size: 13px;
    font-style: italic;
    font-weight: 100;
}

.svydis-masq {
    margin-left: 5px;
}
