.sv-exposed-form {
  .views-exposed-form.views-exposed-widget {
    float: none;
  }
  .views-exposed-widget {
    display:table;
    label, .views-widget{
      display: table-cell;
    }
    .views-widget {
      width: 75%
    }
    label {
      vertical-align: middle;
      text-align: right;
      font-size: 14px;
      padding-right: 10px;
      font-weight: 400;
      line-height: 1;
    }
  }
  .widget-button {
    display: initial;
  }
}

.views-widget-filter-created {
  .views-widget {
    display:table;
    .form-item-created-max {
      label {
        text-align: center;
      }
    }
    .form-item-created-min, .form-item-created-max {
      float: left;
      width: 50%;
      label.control-label, input.form-control {
        width:50%;
        float:left;
        text-align: right;
      }
      label {
        margin-top: 5px;
      }
    }
    .form-item-created-max {
      label.control-label, input.form-control {
        width:50%;
        float:left;
      }
    }

  }
}

// Adressbook
#commerce-addressbook-customer-profile-form {
  .form-type-textfield, .form-type-select {
    display:table;
    width: 100%;
    label.control-label {
      display: table-cell;
      width: 35%;
    }
    input.form-control {
      display: table-cell;
    }
  }
  div.addressfield-container-inline > div.form-item {
    // margin:0px;
  }
  .form-wrapper.form-group {
    margin-bottom: 0px;
    .glyphicon-ok {
      display: none;
    }
  }
  .form-group {
    margin-bottom: 10px;
  }
  .form-item-commerce-customer-address-und-0-premise {
    label.control-label {
      position: relative!important;
      height:auto;
      overflow: auto;
    }
  }
}

// table-layout:

.sv-table {
  // padding-bottom: 20px;
  .form-item {
    vertical-align: top;
  }
  .table-responsive {
    // padding:20px 20px;
    font-size:15px;
    // border: 1px solid #ddd;
    table {
      margin-bottom: 0px;
      tbody>tr>td {
        padding:3px;
        text-align: center;
      }
      tbody>tr {
        td.views-field-line-item-title {
          text-align: left;
        }
        td.views-field-field-commerce-order-title {
          text-align: left;
        }
      }
      tr {
        // text-align: right;
      }

      tbody>tr:nth-of-type(odd) {
        background-color: transparent;
      }
      thead>tr>th {
        border:0px;
      }
      tbody>tr>td, tbody>tr>th, tfoot>tr>td, tfoot>tr>th {
        border:0px;
        background-color:transparent;
        padding:10px;
        font-size: 14px;
        color:#737a7f;
        a {
          color:#737a7f;
          &:hover {
            color: #585858;
          }
        }
        border-bottom: 1px solid #ddd;
      }
      tbody>tr.active:hover>td, tbody>tr.active:hover>th, tbody>tr:hover>.active, tbody>tr>td.active:hover, tbody>tr>th.active:hover {
        background-color:transparent;
      }
      thead>tr>td, thead>tr>th {
        border:0px;
        background-color:#f5f5f5;
        color:#737a7f;
        font-size:13px;
        padding:10px;
        text-align: center;
        vertical-align: middle;
        a {
          color:#737a7f;
          img {
            display: inline-block;
            margin-left: 5px;
          }
        }
      }
      td.views-field-commerce-order-total {
        min-width: 100px;
        padding: 10px 0px;
        text-align: center;
      }
      td.views-field-order-id, td.views-field-commerce-reorder-button {
        padding: 1px;
        vertical-align: middle;
        text-align: center;
      }
      .component-type-commerce-price-formatted-amount, #your-order {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        td {
          padding-top:10px;
        }
        td:last-child {
          min-width: 90px;
        }
      }
    }
    thead>tr>th {
      vertical-align: top;
    }
  }
}

// User order form
// Table
#user-order-form--line-item {
  font-size: 12px;
  width: 100%;
  margin-bottom: 10px;
  .text-left {
    text-align: left!important;
  }
  td:nth-child(1) {
    vertical-align: bottom;
    text-align: left;
    padding: 0 10px;
  }
  td:nth-child(2) {
    text-align: left;
  }
  td > label + div {
    display: inline-block;
    margin-left: 10%;
  }
  td .form-autocomplete {
    .input-group {
      width: 100%;
      padding-right: 5px;
    }
  }
  td .form-item-name.form-type-textfield.form-autocomplete {
  }
  td {
    text-align: center;
  }
  .form-group {
    margin-bottom: 0px;
  }
  span.input-group-addon {
    display:none;
  }
  .ajax-progress.ajax-progress-throbber {
    position: absolute;
    right: 17px;
    top: 7px;
    z-index: 10;
  }
  label {
    font-size: 13px;
    line-height: 1.42857143;
  }
  .btn-default {
    padding: 7px 12px 5px;
  }
  .form-item-quantity, .form-item-manager-discount {
    input {
      max-width: 100px;
      margin: 0 auto;
    }
  }
  #product_stock, #product_base_price, #product_discount, #product_discount_price, #sum_price_without_vat, #sum_price_vat {
    line-height: inherit;
  }
}
.clean-link button {
  background: transparent;
  border: 0;
  padding: 0 6px;
  text-decoration: underline;
  color: #444;
  padding-bottom: 10px;
  &:hover {
    opacity:0.8;
    background: transparent;
  }
}
.product-add-button {
  max-width: 45px;
}
.product-name-input {
  max-width: 230px;
  text-align: left;
}
.table-item-label {
  max-width: 65px;
}
.table-item-value {
  min-width: 60px;
  padding: 0 5px;
}
.page-price-listing table#user-page-listing-form--line-item {
  td {
    padding-bottom: 0;
  }
  tr:first-child td, tr:last-child td:not(.clean-link) {
    padding-bottom: 15px;
  }
}
#user-page-listing-form--line-item {
  td > label + div {
    display: inline-block;
    margin-left: 10%;
  }
  .form-item-manager-discount {
    min-width: 130px;
  }
  #edit-checkvat {
    display: block;
    margin-bottom: -5px;
    text-align: center;
    max-width: 270px;
  }
  .add-line-item__unit-code-wrap {
    min-width: 60px;
  }
  #add-line-item__unit-code {
    height: 34px;
    vertical-align: bottom;
    padding-top: 7px;
  }
  .form-item-quantity, .form-item-manager-discount {
    padding: 0px 5px;
  }
  .product-name-input {
    max-width: inherit;
  }
  .ajax-progress.ajax-progress-throbber {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    top: 7px;
  }
}
#product_info {
  position: relative;
  display: inline-block;
  top: -28px;
  left: -17px;
  z-index: 10;
  float:right;
  height: 0;
  padding:0px;
}
#order_items {
  td:nth-child(2) {
    width: 100%;
    text-align: left;
  }
  td:nth-child(2) .title-wrap {
    min-width: 240px;
  }
  td {
    // min-width:70px;
    text-align: center;
  }
  td.views-field.views-field-line-item-title {
    width: 300px;
  }
  .btn-default {
    padding: 7px 12px 5px;
  }
}
.line-item-add-to-cart, .sv-popup-add-cart {
  .commerce-add-to-cart > div {
    vertical-align: center;
    text-align: center;
    display:table;
    width: 100%;
    .add-cart-quant, .btn-add-cart {
      display: table-cell;
    }
    .add-cart-quant {
      max-width: 80px;
      min-width: 70px;
      input {
        min-width: 35px;
      }
    }
    .form-item-quantity {
      width: 100%;
      display:table;
      .commerce-quantity-plusminus-link, input.form-control.form-text {
        display: table-cell;
      }
    }
    .add-cart-attr {
      width: 100%;
      display: table-caption;
    }
    .attr-wrap {
      vertical-align: center;
      text-align: left;
      display:table;
      width: 100%;
      .form-item.form-type-select {
        margin-bottom: 5px;
      }

      // .form-item.form-type-select {
      //     label.control-label {
      //         display: table-cell;
      //     }
      //     select.form-select {
      //         display: table-cell;
      //         height: 100%;
      //     }
      // }
    }
  }
  input {
    height: 30px;
    text-align: center;
  }
  .btn-add-cart {
    padding: 7px 15px;
    vertical-align: inherit;
  }
}
.sv-product-line-item {
  padding: 10px 0px;
  border-bottom: 1px solid #ddd;
  .line-item-price {
    text-align: center;
    color:#999999;
    font-size:18px;
    font-weight: 400;
    margin-top: 5px;
  }
  .line-item-sku {
    font-size:13px;
    color:#999999;
    font-weight: 400;
  }
  .commerce-add-to-cart {
    .btn-add-cart {
      font-size: 0px;
      i{
        font-size: 15px;
        margin-right: 0px;
      }
    }
  }
  .add-cart-quant {
    .form-item {
      margin-bottom: 0px;
    }
  }
  h5 {
    margin-bottom: 5px;
  }
  .delete-link {
    margin-top: 27px;
  }
}
.sv-related-order-items .view {
  .views-row:last-child  {
    .sv-product-line-item{
      border-bottom: 0px;
    }
  }
}
.sv-related-order-items {
  border: 1px solid #cccccc;
  padding: 15px;
  border-radius: 3px;
  .view-content {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -5px;
    padding-right: 5px;
  }
}

.sv-client-info {
  table {
    width: 100%;
    td:first-child {
      width: 35%;
      text-align: right;
      vertical-align: top;
      padding-right: 10px;
      padding-top: 4px;
    }
    td:last-child {
      text-align: center;
      vertical-align: middle;
    }
  }
  .field-name-field-commerce-order-title label, .field-name-field-commerce-order-notes label {
    display:none;
  }
  .div-container {
    min-height: 30px;
    padding-bottom: 5px;
    border: 1px dashed #ccc;
    margin-bottom: 10px;
    padding-top: 5px;
    font-weight: 300;
  }
  textarea,
  input {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    min-height: 30px;
    border: 1px dashed #ccc;
    padding-top: 5px;
    font-weight: 300;
  }
  textarea {
    border-bottom: 0;
  }
}

.view-order-missing-line-items {
  margin-bottom: 35px;
  margin-top: 10px;
  .views-field.views-field-line-item-title {
    text-align: left;
  }
}

.red-state {
  float:left;
  height: 15px;
  width: 15px;
  background-color: #d44554;
  margin-left: 10px;
  margin-top: 3px;
}
.green-state {
  float:left;
  height: 15px;
  width: 15px;
  background-color: #70c066;
  margin-left: 10px;
  margin-top: 3px;
}
.orange-state {
  float:left;
  height: 15px;
  width: 15px;
  background-color: #ffcc00;
  margin-left: 10px;
  margin-top: 3px;
}
.state-label {
  float:right;
  font-size: 14px;
}

// Base
.margin-center {
  display: block;
  margin: 0 auto;
}
.table {
  display: table;
  .table-cell {
    display: table-cell;
  }
}
.table.aligned {
  .table-cell {
    text-align: center;
  }
  .table-cell:first-child {
    text-align: left;
  }
  .table-cell:last-child {
    text-align: right;
  }
}
.table.aligned-center {
  .table-cell {
    text-align: center;
  }
}
.row-table {
  display: table;
  width:100%;
  .col-cell {
    float: none;
    display: table-cell;
    vertical-align: top;
  }
}
.col-table {
  display: table;
  width: 100%;
  .row-cell {
    display: table-row;
  }
}

// Modal
#modalContent .ctools-modal-dialog.modal-dialog .modal-content {
  overflow: hidden;
  .modal-body {
    overflow: auto;
  }
}
#modalContent {
  &.modal-special-offer-modal {
    .ctools-modal-dialog {
      &.modal-dialog {
        .modal-content {
          height: 95vh;
        }
        .modal-body {
          height: 85vh;
        }
      }
    }
  }
}

.add-cart-item {
  .add-cart-label, .add-cart-value {
    display: inline-block;
  }
}
.add-cart-message-wrapper.add-cart-modal {
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
  position: inherit;
  box-shadow: none;
}
.add-cart-modal {
  .btn-sm {
    margin-bottom: 10px;
  }
  .add-cart-item {
    font-size: 13px;
    font-weight: 400;
  }
  .popup-info {
    font-size: 13px;
  }
  .table {
    margin-bottom: 10px;
  }
  .cart-info {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 10px;
  }
  h3 {
    margin-bottom: 10px;
  }
  .commerce-add-to-cart {
    .form-type-select {
      margin-bottom: 10px;
    }
  }
}
.node--product_display--add_to_cart, .node--product_display--add_to_cart_co {
  margin-bottom: 10px;
  padding-bottom: 10px;
  .table.aligned-center {
    margin-bottom: 0px;
  }
}
.new-item-details div {
  margin-bottom: 0px;
}
// Add to cart modal
.add-cart-message-wrapper {
  .new-item-details {
    overflow: scroll;
    max-height: 300px;
    overflow-x: hidden;
    margin-top: 0px;
    .popup-related-products {
      margin-bottom: 10px;
    }
  }
}
// Masquerade theming
.block-masquerade {
  .form-item-masquerade-user-field {
    .input-group-addon {
      display:none;
    }
    .dropdown .dropdown-menu {
      display:block;
      position: absolute;
      top: 32px;
      right: inherit!important;
      li {
        padding: 0px 10px;
      }
    }
  }
  .form-group {
    margin-bottom: 0px;
  }
  .container-inline {
    display: table;
    width: 100%;
  }
  .container-inline > .form-item-masquerade-user-field.form-autocomplete {
    display: table-cell;
    vertical-align: top;
  }
  .container-inline > button {
    display: table-cell;
    vertical-align: top;
    border-radius: 0;
    border-left: 0;
  }
  .description {
    font-size: 13px;
    font-style: italic;
    line-height: 18px;
    padding-top: 5px;
  }
}


.no-text-link a {
  font-size: 0px;
}

#user-profile-form {
  label {
    font-size: 13px;
  }
  .help-block {
    font-size: 13px;
  }
}

#block-commerce-extra-plus-commerce-extra-plus h1, #block-commerce-extra-plus-commerce-extra-plus .page-header {
  display: none;
}

// Responsive

@media (min-width: 992px) {
  .sm-padding-left {
    padding-left: 5px;
  }
  .sm-padding-right {
    padding-right: 5px;
  }
}
@media (max-width: 992px) {
  .sv-related-order-items {
    h5 {
      margin-top: 10px;
    }
    .delete-link {
      margin-top: 57px;
    }
  }
  .node--product_display--add_to_cart_co {
    text-align: center;
    img {
      margin: 0 auto;
    }
    .table.aligned-center {
      .table-cell {
        display: block;
      }
    }
    .commerce-add-to-cart > div {
      .add-cart-quant, .btn-add-cart {
        display: block;
      }
      .add-cart-quant {
        margin: 0 auto;
        margin-bottom: 10px;
        input {
          width: 60px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .sv-related-order-items {
    .line-item-price {
      font-size: 13px;
    }
  }
}

.view-commerce-addressbook-defaults .views-field-commerce-customer-address-premise {
  display: none;
}
.node--product_display--add_to_cart_co .commerce-price-savings-formatter-list,
.view-id-recommended_products .commerce-price-savings-formatter-list,
.view-id-favourite_products .commerce-price-savings-formatter-list{
  font-size: 80%;
  text-decoration: line-through;

}
.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #ffff;
}
.line-item-price .commerce-price-savings-formatter-list td {
    border-top: unset;
}
