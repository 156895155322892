
.btn-sticky {
    position: fixed;
    right: 10px;
    margin-top: 10px;
    z-index: 100;
    .fa {
        margin-right: 10px;
    }
    padding-right: 30px;
    padding-left: 15px;
}
.btn-sticky-close {
    background-color: #d44554;
    padding: 6px 10px;
    height: 34px;
    color: #fff;
    font-size: 12px;
    .fa {
        margin-right: 0px;
    }
    .ajax-progress {
        display: none;
    }
    z-index: 101;
}
.btn.btn-sticky:hover, .btn-sticky.btn-sticky-close:hover {
    color: #E0E0E0;
    background-color: #d44554;
}

@media (max-width: 992px) {
    .btn-sticky {
        font-size: 0px;
        .fa {
            font-size: 20px;
            margin-right: 0px;
        }
    }
}

.sv-compare-table {
    table {
        width:100%;
        th {
            padding: 10px;
        }
        td {
            padding: 10px 30px;
        }
        tr:nth-child(odd) {
            background-color: #f5f5f5;
        }
        tr:nth-child(1), tr.views-row-last {
            background-color: transparent;
        }
        .views-row-first th:not(:first-child) {
            border-top: 1px solid #d2d3d4;

        }
        .views-row-last td {
            border-bottom: 1px solid #d2d3d4;
        }
        tr th:not(:first-child) {
            border-left: 1px solid #d2d3d4;
            border-right: 1px solid #d2d3d4;
        }
        tr td {
            border-left: 1px solid #d2d3d4;
            border-right: 1px solid #d2d3d4;
        }
        img {
            margin: 0 auto;
        }

    }
    .views-field-field-pr-manufacturer, .views-field-commerce-price {
        text-align: left;
    }
}

.sv-compare-table .views-table tr td, .sv-compare-table thead tr th:not(:first-child) {
    width: 320px;
}
