/* all */
.hd-search ::-webkit-input-placeholder {
  font-size: 13px;
}

.hd-search ::-moz-placeholder {
  font-size: 13px;
}

/* firefox 19+ */
.hd-search :-ms-input-placeholder {
  font-size: 13px;
}

/* ie */
.hd-search input:-moz-placeholder {
  font-size: 13px;
}

.page-header {
  padding: 0px;
  border-bottom: 0px;
  margin: 40px 0px 0px;
}

.inner-sides-sm {
  padding: 0px 20px;
}

.margin-center {
  display: block;
  margin: 0 auto;
}

.outer-top {
  margin-top: 40px;
}

.outer-top-xs {
  margin-top: 10px;
}

.outer-md {
  margin: 30px 0px;
}

.outer-sm {
  margin: 20px 0px;
}

.outer-xs {
  margin: 10px 0px;
}

.outer-bottom {
  margin-bottom: 40px;
}

.outer-bottom-md {
  margin-bottom: 30px;
}

.outer-bottom-sm {
  margin-bottom: 20px;
}

.outer-bottom-xs {
  margin-bottom: 10px;
}

.outer-top-md {
  margin-top: 30px;
}

.outer-top-sm {
  margin-top: 20px;
}

.outer {
  margin: 40px 0px;
}

.inner-sm {
  padding: 20px 0px;
}

.inner {
  padding: 40px 0px;
}

.inner-bottom {
  padding-bottom: 40px;
}

.inner-top-sm {
  padding-top: 20px;
}

.padding-sm {
  padding: 20px;
}

.padding-xs {
  padding: 10px;
}

.font-sm {
  font-size: 12px;
}

// md
@media (min-width: 992px) {
  .outer-push-md {
    padding-left: 10%;
  }
  .border-left-md {
    border-left: 1px solid #d2d3d4;
  }
}

section.block-boxes {
  padding-bottom: 10px;
}

section#block-svydis-common-add-new-address {
  padding-bottom: 0px;
}

.bordered-bottom {
  border-bottom: 1px solid #e5e5e5;
}

// .bordered-bottom:last-child {
//     border: 0px;
// }
.bordered-div {
  border: 1px solid #d2d3d4;
  border-bottom: 3px solid #d2d3d4;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

// Breadcrumb
.commerce-checkout-progress li.active, ol.breadcrumb li.active {
  font-weight: 500;
}

ol.inline.commerce-checkout-progress li {
  float: left;
  height: 40px;
  width: auto;
  list-style: none;
}

.block-commerce-checkout-progress, .breadcrumb {
  padding: 0px;
  background-color: #f5f5f5;
  border-radius: 5px;
  // margin-top: 20px;
  li {
    padding: 9px;
    font-size: 14px;
  }
  li:before {
    content: "";
    float: left;
    width: 25px;
    height: 45px;
    background: url(../img/line.svg) 0px 0px;
    position: relative;
    left: -5px;
    top: -12px;
  }

}

.progress-home {
  float: left;
  color: #d2d2d2;
  .fa-home, .fa-shopping-cart {
    margin: 12px 5px 12px 20px;
  }
}

.breadcrumb > li + li:before {
  padding: 0;
  content: "";
}

ol.breadcrumb li {
  height: 40px;
  width: auto;
}

.breadcrumb {
  margin-bottom: 0px;
}

// Simple table
#contact-table {
  font-weight: 400;
  tr {
    td:first-child {
      width: 20%;
      border-right: 1px solid #cccccc;
      padding-right: 10px;
    }
    td:not(:first-child) {
      padding-left: 30px;
    }
  }
}

// Gallery
.sv-gallery {
  padding-bottom: 30px;
  h2 {
    margin-bottom: 40px;
    font-size: 28px;
    font-weight: 400;
    text-transform: uppercase;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    -webkit-transition: .2s all;
    margin: 0 auto;
  }
  img:hover {
    -webkit-filter: brightness(80%);
  }
  .slick-slide {
    padding: 0px 5px;
  }
}

#block-bean-car-paintings, #block-bean-industrial-paintings, #block-svydis-common-taxonomy-term-description {
  padding-bottom: 0px;
}

#block-bean-car-paintings,
#block-bean-industrial-paintings {
  .bean-gallery {
    h1 {
      display: none;
    }
  }

  .sv-gallery-sm {
    margin-top: 20px;
  }
}

@media (min-width: 992px) {
  .sv-gallery-sm.sv-gallery {
    padding-bottom: 0px;
    .slick-track {
      height: 150px;
    }
  }
}

@media (min-width: 1200px) {
  .sv-gallery-sm.sv-gallery {
    .slick--optionset--gallery-display {
      .slick-track {
        height: 185px;
      }
    }
  }
}

.sv-gallery .slick-track {
  height: 185px;
}

.slick__arrow {
  text-align: center;
  margin-top: 25px;
  font-size: 26px;
  .slick-arrow, .gallery-pager, ul > li {
    display: inline-block;
  }
  .slick-arrow {
    cursor: pointer;
  }

}

.gallery-pager {
  position: relative;
  top: -4px;
}

.slick-dots {
  li.slick-active button {
    background: #737a7f;
  }
  button {
    background: transparent;
    border: 1px solid #737a7f;
    border-radius: 44px;
    font-size: 0;
    height: 15px;
    width: 15px;
    margin: 0px 5px;
    padding: 0px;
    cursor: pointer;
  }
}

.media-icon.media-icon--lightbox.media-icon--photobox {
  display: none;
}

// Pagination
ul.pagination {
  border-radius: 0px;
  .pager-last, .pager-first {
    display: none;
  }
  .next, .prev {
    font-size: 25px;
    padding: 6px 0px;
  }
  li.prev > a, li.next > a {
    color: #ddd;
    border: 0px;
    padding: 7px 0;
    margin: 0px 10px;
  }
  li.prev > a:focus, li.prev > a:hover, li.prev > span:focus, li.prev > span:hover,
  li.next > a:focus, li.next > a:hover, li.next > span:focus, li.next > span:hover {
    background-color: transparent;
  }
  li > a, li > span {
    padding: 15px;
    margin: 0 3px;
    color: #737a7f;
  }
  li > a:focus, li > a:hover, li > span:focus, li > span:hover, li.active > span, li.active > a, li.active > a:focus, li.active > a:hover, li.active > span:focus, li.active > span:hover {
    color: #737a7f;
    background-color: #eee;
    border-color: #ddd;
  }
  li:first-child > a, li:first-child > span {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin: 0 3px;
  }
  li:last-child > a, li:last-child > span {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    margin: 0 3px;
  }
}

.pagination li {
  display: inline-block;
}

// Paragraphs

.field-name-field-paragraph {
  h2 {
    margin-bottom: 10px;
  }
  p {
    font-weight: 400;
  }
  ul {
    li {
      font-weight: 400;
    }
  }
}

.field-type-paragraphs {
  .outer-top:first-child {
    margin-top: 0px;
  }
}

#block-boxes-map-block {
  p {
    margin-bottom: 0px;
  }
  iframe {
    margin-bottom: -6px;
  }
}

//Autocomplete new line text
.products_autocomplete {
  white-space: pre-line; /* collapse WS, preserve LB */
}

.node-basic-page.node--basic_page--full {
  h3 {
    font-weight: 700;
    font-size: 22px;
  }
  table tr td:first-child {
    border-right: 1px solid #ccc;
  }
  table tr td:last-child {
    padding-left: 20px;
  }
}

.tabs--primary {
  margin-top: 20px;
}

.footer .menu.nav {
  li > a {
    margin-left: 15px;
    .fa {
      position: absolute;
      left: -15px;
      top: 2px;
    }
  }
}

.view-product-sub-categories.view-id-product_sub_categories.view-display-id-block, .view-car-paintings-categories.view-display-id-block_1, .view-industrial-paintings-categories.view-display-id-block_1 {
  .view-content .views-row {
    margin-left: 15px;
    position: relative;
    .fa {
      position: absolute;
      left: -15px;
      top: 2px;
    }
  }
}

#block-menu-menu-info-footer-menu,
#block-menu-menu-main-footer-menu {
  ul {
    //display: inline-block;
    //text-align: center;
    &.menu {
      display: block;
    }
    & > li {
      margin-left: 15px;
      position: relative;
      .fa {
        position: absolute;
        left: -15px;
        top: 2px;
      }
    }

  }
}

#block-menu-menu-main-footer-menu {
  ul.menu {
    display: inline-block;
  }
}

.block-masquerade .form-item-masquerade-user-field .dropdown .dropdown-menu {
  display: block;
  position: absolute;
  top: 32px;
  width: 230px;
}

.block-masquerade .form-item-masquerade-user-field .dropdown .dropdown-menu li {
  padding: 0 10px;
  overflow: hidden;
}

.block-masquerade #quick_switch_links ul {
  padding: 0;
  list-style-type: none;
}

.paragraphs-item-shop {
  min-height: 200px;
}

.contacts-table-fix td {
  padding-bottom: 3px;
  padding-left: 20px;
}

@media screen and (max-width: 600px) {
  .contacts-table-fix table {
    width: 100% !important;
    td {
      padding-left: 10px !important;
      padding-right: 5px !important;
    }
  }
}

.sv-form-table.table {
  tbody > tr > td, tbody > tr > th, tfoot > tr > td, tfoot > tr > th, thead > tr > td, thead > tr > th {
    border-top: 0px;
    vertical-align: bottom;
  }
  .form-item-quantity {
    max-width: 90px;
  }
  .form-group {
    margin-bottom: 0px;
  }
}

.sv-table {
  .col-small {
    width: 70px;
    margin: auto;
    .form-item {
      margin-bottom: 0px;
    }
  }
}

.total-orders-price, .total-order-price {
  float: right;
  margin-top: 30px;
  font-size: 20px;
}

.inline-label {
  label {
    display: inline-block;
    width: 24%;
    text-align: right;
    vertical-align: middle;
    font-size: 14px;
    padding-right: 10px;
    font-weight: 400;
    line-height: 1;
  }
  .form-item > input, .form-item > .input-group {
    display: inline-block;
    width: 75%;
  }
  .input-group-addon {
    display: none;
  }
  .dropdown {
    width: 77%;
    float: right;
  }
  .form-item-warehouse-worker label {
    float: left;
    margin-top: 9px;
  }
}

.sv-filter {
  .container-inline-date {
    display: inline-block;
    width: 50%;
  }
  .form-item {
    label, .date-padding {
      display: inline-block;
      width: 50%;
      float: left;
      input {
        width: 100%;
      }
    }
  }
  .form-item label {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 30px;
    text-align: right;
    vertical-align: middle;
    padding-right: 10px;
    font-weight: 400;
    line-height: 1;
    font-size: 13px;
  }
  .help-block {
    margin-bottom: 0px;
  }
}

.form-item-warehouse-worker {
  text-align: right;
}

body:not(.admin-page).page-user-orders .action-links {
  display: none;
}

.sv-table {
  .table > caption + thead > tr:first-child > td, .table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > td, .table > thead:first-child > tr:first-child > th {
    text-align: left;
  }
}

.sv-table .table-responsive table tbody > tr > td, .sv-table .table-responsive table tbody > tr > th, .sv-table .table-responsive table tfoot > tr > td, .sv-table .table-responsive table tfoot > tr > th {
  text-align: left;
}

.view-taxonomy-term-data {
  margin-top: 40px;
}

.view-header .view-taxonomy-term-data {
  display: none;
}

.page-prekiu-paemimas-sandelio-likutis {
  .sv-table .table-responsive table {
    thead > tr > th:last-child, tbody > tr > td :last-child {
      width: 100px;
    }
  }
}

.contact-us-info-image-phone {
  text-align: justify;

  br {
    line-height: 35px;
  }
}

.field-name-field-contact-contacts table,
#node-1595 table {
  &,
  td {
    border: 0;
  }
}

.questionpro-modal {
  .modal-body {
    max-height: none !important;
    .btn {
      width: 100%;
      background-color: #d44554;
      border-color: #fff;
    }
  }
}
.modal-special-offer-modal {
  .modal-title {
    color: #d44554;
    font-size: 20px;
    display: inline-block;

    & ~ .svydis-custom-language-switcher {
      float: right;
      margin: 3px 15px 0 0;
    }
  }

  #modal-content {
    width: 100% !important;
  }
}
.view-special-offer-list {
  .inner-cart {
    position: relative;
    padding: 10px 20px 20px;
    border: 1px solid #e5e5e5;
  }
  .col-lg-4 {
    padding: 15px;
  }

  .views-field-field-photos {
    img {
      margin: 0 auto;
    }
  }

  .col-1 {
    overflow: hidden;

    @media (min-width: 1200px) {
      &:nth-child(4n) {
        clear: both;
      }
    }

    @media (max-width: 1199px) {
      &:nth-child(2n+1) {
        clear: both;
      }
    }
  }
  .views-field-field-pr-discount {
    position: absolute;
    left: 0;
    .field-content {
      background-color: #d44554;
      color: #fff;
      font-size: 16px;
      display: inline-block;
      padding: 9px 17px;
    }
  }
  .views-field-field-photos {
    padding-top: 40px;
  }
  .views-field-title {
    min-height: 66px;
    padding: 10px 0;
    a {
      @include multiline-truncate(16px, 1.5, 2);
      color: #555555;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
  .views-field-sku {
    padding-bottom: 20px;
    font-size: 13px;
    color: #555555;
  }
  .views-field-field-calculated-price {
    .commerce-price-savings-formatter-list {
      color: #555555;
      text-decoration: line-through;
      vertical-align: top;
    }
    .commerce-price-savings-formatter-price {
      font-size: 32px;
      color: #d44554;
      font-weight: 500;
      text-align: right;
    }
    table {
      margin-bottom: 0;
    }
    .commerce-price-savings-formatter-price-row {
      background-color: transparent;
      td {
        border: none;
        padding: 0;
      }
    }
  }
  .views-field-nothing {
    padding-bottom: 20px;
  }
  .views-field-add-to-cart-form {
    .form-item-quantity {
      @include clearfix();
    }
    .commerce-add-to-cart .form-item-quantity {
      width: 106px;
      margin: 0 auto;
      text-align: center;
      border: 1px solid #e5e5e5;
      .commerce-quantity-plusminus-link {
        float: left;
        a {
          display: block;
          background-color: #ededed;
          color: #555555;
          font-size: 18px;
          height: 50px;
          line-height: 50px;
          width: 25px;
          margin: 0;
          -webkit-border-radius: 0px;
          border-radius: 0px;
          padding: 1px;
          font-weight: inherit;
        }
      }

      .commerce-quantity-plusminus-link-increase {
        float: right;
      }

      .commerce-quantity-plusminus-link a:hover, .commerce-quantity-plusminus-link:active,
      .commerce-quantity-plusminus-link:visited, .commerce-quantity-plusminus-link:focus {
        text-decoration: none;
        background-color: #ededed;
      }
      .commerce-quantity-plusminus-link-decrease {
        padding-right: 2px;
      }
      .commerce-quantity-plusminus-link-increase {
        padding-left: 2px;
      }
      input.form-control {
        width: 50px;
        float: left;
        text-align: center;
        height: 48px;
        border-radius: 0px;
        border: none;
        font-size: 18px;
        color: #555555;
      }
    }
    .btn-add-cart {
      margin-top: 20px;
      height: 50px;
    }
  }
}
.close:focus,
.close:hover {
  outline: none;
}

.svydis-custom-language-switcher {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    float: left;
    margin: 0 5px;

    &.active {
      a {
        color: #d44554;
        text-decoration: underline;
        font-weight: 600;
      }
    }
  }

  a {
    font-size: 18px;
    text-transform: uppercase;
  }
}
