// pdf
.pdf-page-layout {
  font-family: "DejaVu Sans", sans-serif;
  font-size: 15px;
  color: #777777;
  border: 1px dashed;
  .invoice {
    padding: 45px;
  }
  .invoice-header {
    width: 100%;
    display: inline-block;
  }
  .sv-company-info {
    display: inline-block;
    width: 63%;
    height: auto;
    float: left;
    text-align: left;
  }
  .sv-manager-info {
    display: inline-block;
    width: 33.33333%;
    float: right;
    text-align: left;
  }
  .sv-center-info {
    width: 100%;
    text-align: center;
    margin: 40px 0px;
  }
  h3 {
    text-align: left;
  }
  h2 {
    font-size: 22px;
    font-weight: 400;
    padding: 0px;
    margin: 0px;
    margin-bottom: 30px;
  }
  h1 {
    font-size: 28px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0px;
    margin: 0px;
  }
  .hidden {
    display: none;
  }
  .pdf-page-img {
    display: block !important;
  }
  .outer-xs {
    padding: 15px 0px;
  }
  .outer-top {
    margin-top: 30px;
  }
  p {
    margin: 5px 0px;
    text-align: left;
  }
  .line-items {
    margin: 30px 0;
  }
  .order-total {
    padding-top: 10px;
    float: right;
    font-size: 16px;
    margin-right: 42px;
    font-weight: bold;
  }
  .order-price {
    display: inline-block;
    margin-left: 10px;
  }
  .sv-table .table-responsive {
    table {
      text-align: left;
      margin-bottom: 10px;
      width: 100%;
      table tbody {
        padding-bottom: 10px;
      }
    }
    > table {
      > tbody {
        border-bottom: 0;
      }
    }
    table thead th {
      background-color: #f5f5f5;
      text-align: left;
      padding: 10px;
      font-weight: 400;
      font-size: 13px;
      &.views-field-field-photos {
        width: 155px;
      }
      &.views-field-commerce-total,
      &.views-field-commerce-unit-price {
        width: 100px;
      }
      &.views-field-quantity {
        width: 78px;
        text-align: center;
      }
    }
    tbody > tr > td {
      padding: 0;
      border-bottom: 0;
      font-size: 14px;
      &.views-field-field-photos {
        width: 155px;
      }
      &.views-field-commerce-total,
      &.views-field-commerce-unit-price {
        width: 100px;
      }
      &.views-field-quantity {
        width: 78px;
        text-align: center;
      }
      .more-info-info-row {
        table {
          tbody {
            border-bottom: 0;
             tr > td {
              text-align: left;
             }
          }
        }
      }
      > table {
        tr >td {
          padding: 10px;
          padding-left: 0px;
          text-align: center;
        }
      }
    }
  }
}
.views-field.views-field-field-pr-unitcode {
  width: 60px;
}
