.node-type-product-display section#block-system-main {
  padding-bottom: 0;
}

.node--product_display--full {
  h2 {
    margin-bottom: 20px;
  }
  .main-price {
    margin: 0;

    .table {
      margin: 0;
    }
    tr {
      background-color: transparent !important;
    }
    td {
      border: 0;
      padding: 0;
    }
    .commerce-price-savings-formatter-list {
      text-align: center;
      text-decoration: line-through;
    }
    .commerce-price-savings-formatter-price {
      color: #d44554;
      font-size: 40px;
      text-align: center;
    }
  }

  .old-price {
    text-align: center;
    text-decoration: line-through;
  }

  .product-display__discounts-available {
    font-size: 14px;
    margin-top: 15px;

    .product-display__discounts-available__title {
      padding: 5px;
    }

    table {
      margin-bottom: 0;
      width: 100%;
    }

    tr:last-child td {
      border-bottom: 1px solid #ddd;
    }

    td {
      padding: 8px 15px;
    }

    .align-left {
      text-align: left;
    }

    .align-right {
      text-align: right;
    }
  }
}

.node--product_display--add_to_cart {
  .table-cell {
    vertical-align: top;
  }

  .commerce-price-savings-formatter-price,
  .commerce-price-savings-formatter-list {
    background-color: transparent !important;

    .price-amount {
      border: 0;
      padding: 6px;
    }
  }

  .commerce-price-savings-formatter-list .price-amount {
    text-decoration: line-through;
  }
}



.related-products-field {
  overflow: auto;
  border: 1px solid #d2d3d4;
  h2 {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: normal;
  }
}

.product-small {
  img {
    border: 1px solid #d2d3d4;
  }
  h3 {
    font-size: 14px;
    text-align: center;
    margin-top: 3px;
    padding-bottom: 15px;
    height: 78px;
  }
  a {
    text-decoration: none;
  }
}

.sv-document-files-list {
  .fa-download {
    color: #fff;
    background-color: #d44554;
    padding: 9px 10px;
    margin-right: 10px;
  }
  .field-item {
    margin-bottom: 20px;
  }
  img.file-icon {
    display: none;
  }
}

.field-type-double-field {
  .field-item {
    overflow: hidden;
  }
  .even {
    background: #f5f5f5;
  }
  .double-field-first {
    font-weight: bold;
  }
  .double-field-first, .double-field-second {
    width: 50%;
    float: left;
    padding: 10px;
  }
}

.need_advice {
  text-align: center;
  .phone {
    font-size: 30px;
    i.fa-phone {
      margin-right: 10px;
    }
  }
}

.product-cart-form {
  .commerce-add-to-cart .form-item-quantity {
    width: 35%;
    float: left;
    text-align: center;
    .commerce-quantity-plusminus-link {
      width: 25%;
      float: left;
      a {
        background-color: #d44554;
        color: #FFF;
        font-size: 25px;
        height: 54px;
        vertical-align: middle;
        display: table-cell;
        width: inherit;
        -webkit-border-radius: 0px;
        border-radius: 0px;
        padding: 0px;
        font-weight: inherit;
      }
    }

    .commerce-quantity-plusminus-link a:hover, .commerce-quantity-plusminus-link:active,
    .commerce-quantity-plusminus-link:visited, .commerce-quantity-plusminus-link:focus {
      text-decoration: none;
      background-color: rgba(212, 69, 84, 0.75);
    }
    .commerce-quantity-plusminus-link-decrease {
      padding-right: 2px;
    }
    .commerce-quantity-plusminus-link-increase {
      padding-left: 2px;
    }
    input.form-control {
      width: 50%;
      float: left;
      text-align: center;
      height: 54px;
      border-radius: 0px;
      border: 1px solid #d44554;
      font-size: 20px;
      color: #d44554;
    }
  }
  .btn-add-cart {
    width: 64%;
    float: left;
    margin-left: 1%;
    height: 54px;
  }
}

.product-cart-form {
  .form-item.form-type-select.form-item-product-id {
    select.form-control, .bootstrap-select {
      width: 100%;
      margin-left: 0px;
    }
  }
  .form-item.form-type-select {
    .commerce-add-to-cart {
      .attribute-widgets {
        margin-bottom: 8px;
      }
    }
    display: inline-block;
    width: 100%;
    margin-bottom: 0px;
    label.control-label {
      display: inline-block;
      float: left;
      width: 35%;
      text-align: right;
      padding-right: 5px;
      padding-top: 5px;
      font-weight: 400;
    }
    select.form-control, .bootstrap-select {
      display: inline-block;
      width: 64%;
      float: left;
      margin-left: 1%;
    }
  }
  #edit-line-item-fields.form-group {
    margin-bottom: 0px;
  }
}

.product-shipping-info .field {
  .field-label, .field-items {
    display: inline-block;
  }
  .fa-truck {
    margin-right: 10px;
  }
}

.sv-contact-block {
  h2 {
    font-size: 28px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 40px;
  }
  h3 {
    margin-bottom: 25px;
  }
  p {
    margin-bottom: 15px;
  }
}

.sv-contact-photo {
  color: #fff;
  .contact-description {
    position: absolute;
    width: 100%;
    bottom: 5px;
    padding: 5px 15px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#737a7f+0,ffffff+68&0.65+0,0+100 */
    background: -moz-linear-gradient(-45deg, rgba(115, 122, 127, 0.65) 0%, rgba(255, 255, 255, 0.21) 68%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(115, 122, 127, 0.65) 0%, rgba(255, 255, 255, 0.21) 68%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(0deg, rgba(115, 122, 127, .65) 0, rgba(65, 65, 66, .21) 68%, rgba(145, 145, 25, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6737a7f', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    .contact-name {
      text-transform: uppercase;
    }
    .contact-position {
      font-size: 13px;
    }
  }
}

.page-akcijos .product-teaser-compare {
  display: none;
}

.bg-person {
  background: url(../img/person.png);
  background-repeat: no-repeat;
  background-position: right 0px top 5px;
}

.btn-sv-large {
  padding: 15px 35px;
  border: 0px;
  border-radius: 0px;
}

.btn-sv-form {
  margin-left: 125px;
}

.btn i.fa-paper-plane {
  margin-right: 10px;
}

.btn-large {
  border-radius: 0px;
  padding: 15px 25px;
  color: #737a7f;
}

.btn-large:hover {
  color: #737a7f;
}

.sv-contact-form {
  .block-title {
    display: none;
  }
}

@media (max-width: 992px) {
  .sv-contact-form {
    .form-item {
      label {
        width: 25%;
      }
    }
    .form-item.checkbox {
      margin-left: 25%;
    }
    .form-margin {
      margin-left: 25%;
    }
  }
}

@media (max-width: 768px) {
  .sv-contact-form .form-item label, .sv-register-form .form-item label {
    text-align: left;
  }
  .sv-contact-form .form-item, .sv-register-form .form-item {
    display: block;
  }
  .sv-contact-form .form-item.checkbox, .sv-contact-form .form-margin {
    margin-left: 0px;
  }
}
