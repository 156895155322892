.form-item-order-purpose {
  text-align: right;
  label {
    margin-right: 10px;
  }
  input {
    display: inline-block;
    width: 32%;

  }
}

.svydis-consignment-approval-wrapper {
  display: none;
}

.form-item-product.form-type-textfield.form-autocomplet {
  .ajax-progress.ajax-progress-throbber {
    position: absolute;
    left: 20px;
    z-index: 100;
    top: 9px;
  }
}

.svydis-consignment-full-page-blocker {
  background-color: #ddd;
  bottom: 0;
  left: 0;
  opacity: .6;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
}

.svydis-consignment-approval-modal {
  background-color: #fff;
  border: 1px solid #dedede;
  left: 50%;
  padding: 30px;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
}

.svydis-consignment-approval-modal .close-button {
  color: #666;
  cursor: pointer;
  font-weight: bold;
  opacity: .3;
  position: absolute;
  right: 15px;
  top: 12px;

  transition: opacity .1s ease-in-out;
}

.svydis-consignment-approval-modal .close-button:hover {
  opacity: .7;
}

.svydis-consignment-approval-modal .control-label {
  font-size: 24px;
  margin-bottom: 20px;
}

.svydis-consignment-approval-modal input {
  margin-bottom: 20px;
  text-align: center;
}

.products-boxes-tabs {
  margin-bottom: 30px;

  li {
    border: 1px solid #ccc;
    color: #737a7f;
    cursor: pointer;
    display: inline-block;
    margin: 0 .5%;
    overflow: hidden;
    padding: 5px 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 24%;

    &:hover,
    &.products-boxes-tabs-active {
      background-color: #cccccc;
      color: #fff;
    }
  }
}

.products-boxes-category {
  display: none;
}

.products-box {
  border: 1px solid #eee;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 30px;
  font-size: 13px;
  min-height: 200px;
  // width: 15%;

  img {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  -moz-box-shadow: 3px 9px 3px -6px rgba(0, 0, 0, .3);
  box-shadow: 3px 9px 3px -6px rgba(0, 0, 0, .3);

  &:hover {
    border: 1px solid #ccc;
  }

  button {
    border: 0;
    white-space: normal;
    font-size: 13px;
    &:active,
    &:focus {
      outline: 0 !important;
      box-shadow: none;
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.products-boxes-category-active {
  display: block;
}

.products-box-title {
  text-align: center;
}

#svydis-consignment-order-history-form {
  .form-type-date-popup {
    margin-right: 0;

    label {
      width: 35%;
    }
  }

  .date-padding {
    float: none;
    width: 60%;

    label {
      display: none;
    }
  }

  .form-item-date-from-time,
  .form-item-date-to-time {
    display: none;
  }
}

#svydis-consignment-order-history-tbl tbody tr {
  cursor: pointer;
}

.table .merged-rows {
  background-color: #fff;
  color: #737a7f;
  vertical-align: middle;
}

.table .svydis-consignment-insufficient-quantity.svydis-consignment-insufficient-quantity td:not(.merged-rows) {
  background-color: #f2dede !important;
}

.logged-in.page-prekiu-paemimas #block-system-main {
  padding-top: 0;
}

#svydis-consignment-order-form {
  .sv-form-table {
    tr td:first-child {
      padding-left: 0px;
    }
    tr td:last-child {
      padding-right: 0px;
    }
  }

  .ajax-progress {
    display: none;
  }
}

#svydis-consignment-warehouse-rests-form {
  .table {
    tr td {
      padding: 0px;
    }
    tr td:last-child {
      text-align: center;
    }
    #edit-button-search {
      text-align: center;
      border-radius: 0;
      width: 100%;
      margin: 0 5px;
    }
  }
}

// warehouse worker page design
.warehouse-worker-page.page-prekiu-paemimas, .warehouse-worker-page.page-user {
  .hd-responsive-container {
    display: none !important;
  }
  #block-lang-dropdown-language {
    display: none;
  }
  #block-search-api-page-search-results {
    display: none;
  }
  .sv-collapse.navbar-collapse .menu:not(.secondary) {
    display: none;
  }
  #footer {
    display: none;
  }
  #block-dc-ajax-add-cart-ajax-shopping-cart-teaser {
    display: none;
  }
}

